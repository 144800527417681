export default {

    /* -- ON SUCCESS -- */
    successMsg: "Thanks - Your order is in!",
    successRoute: "/",

    /* -- TITLE -- */
    title: {
        text: "Legal / Trio",
        centered: true
    },

    /* -- INPUTS -- */
    inputs: [
        {
            name: "legalDescriptionOnly",
            placeholder: "Legal Description Only",
            type: "checkbox"
        },
        {
            name: "orderHomeBook",
            placeholder: "Order Homebook",
            type: "checkbox"
        },
        {
            name: "owner",
            placeholder: "Owner",
            type: "text"
        },
        {
            name: "parcelNumber",
            placeholder: "Parcel Number",
            type: "text"
        },
        {
            name: "address",
            placeholder: "Address",
            type: "text"
        },
        {
            name: "city",
            placeholder: "City",
            type: "text"
        },
        {
            name: "state",
            placeholder: "State",
            type: "text"
        },
        {
            name: "zipcode",
            placeholder: "Zipcode",
            type: "text"
        },
        {
            name: "county",
            placeholder: "County",
            type: "text"
        },
        {
            name: "notes",
            placeholder: "Notes",
            type: "textarea"
        }
    ],

    /* -- BUTTONS -- */
    buttons: [
        {
            text: "Submit",
            type: "submit"
        }
    ]
};
