import fetchOptions from "../../Admin/lib/fetch-options";

export default {

    /* -- TITLE -- */
    title: {
        text: "Please select a sales rep",
        centered: true
    },

    /* -- INPUTS -- */
    inputs: [
        {
            name: "rep",
            placeholder: "Sales Rep",
            type: "select",
            options: new Promise((resolve, reject) => {
                fetch(`/api/reps`, fetchOptions("GET"))
                    .then(res => res.json())
                    .then(reps => {
                        resolve(reps.map(rep => ({
                            value: rep.id,
                            text: rep.name
                        })));
                    })
                    .catch(error => reject(error));
            }),
        }
    ],

    /* -- BUTTONS -- */
    buttons: [
        {
            text: "Submit",
            type: "submit"
        },
        {
            text: "Go Back",
            type: "link",
            url: "/?logout=true",
            forceRefresh: true
        }
    ]
};
