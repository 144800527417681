export default {

    /* -- ON SUCCESS -- */
    successMsg: "Authorization letter has been sent.",
    successRoute: "/",

    /* -- TITLE -- */
    title:{
        text: "Agent Authorization Letter",
        centered: true
    },

    /* -- INPUTS -- */
    inputs: [
        {
            name: "message",
            placeholder: "Letter",
            type: "textarea",
            required: true
        }
    ],

    /* -- BUTTONS -- */
    buttons: [
        {
            text: "Submit",
            type: "submit"
        }
    ]
};
