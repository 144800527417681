import React from "react";
import {Switch, Route} from "react-router-dom";

import Admin from "./Admin";
import Main from "./Main";
import Test from "./Test";
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module';

import config from './config';

ReactGA.initialize(config.GA_ID);
TagManager.initialize({gtmId: config.GTM_ID});
const App = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <Switch>
            <Route path="/admin" render={props => <Admin {...props}/>}/>
            <Route path="/test" render={() => <Test/>}/>
            <Route component={Main}/>
        </Switch>
    );
};

export default App;
