import React from "react";

import {CrudTable} from "./admin-tools";
import fetchOptions from "./lib/fetch-options";

const Users = () => (
    <CrudTable
        columns={[
            {
                name: "firstName",
                display: "First Name",
                type: "text"
            },
            {
                name: "lastName",
                display: "Last Name",
                type: "text"
            },
            {
                name: "company",
                display: "Company",
                type: "text"
            },
            {
                name: "email",
                display: "Email",
                type: "text"
            },
            {
                name: "phone",
                display: "Phone Number",
                type: "text"
            },
            {
                name: "rep",
                display: "Rep",
                type: "select",
                options: new Promise((resolve, reject) => {
                    fetch("/api/reps", fetchOptions("GET"))
                        .then(res => res.json())
                        .then(json => {
                            resolve(json.map(rep => ({
                                value: rep.id,
                                text: rep.name
                            })));
                        })
                        .catch(reject);
                }),
                createFilter: true,
                prependedFilterOptions: [{
                    text: "No Rep Assigned",
                    value: 0
                }]
            },
            {
                name: "region",
                display: "Region",
                type: "readonly-select",
                superAdminOnly: true,
                options: new Promise((resolve, reject) => {
                    fetch("/api/regions", fetchOptions("GET"))
                        .then(res => res.json())
                        .then(json => {
                            resolve(json.map(region => ({
                                value: region.id,
                                text: region.name
                            })));
                        })
                        .catch(reject);
                })
            },
            {
                name: "role",
                display: "Role",
                type: "select",
                superAdminOnly: true,
                options: [{
                    value: "user",
                    text: "User"
                }, {
                    value: "admin",
                    text: "Admin"
                }, {
                    value: "national-admin",
                    text: "National Admin"
                }]
            }
        ]}
        read={() => {
            return new Promise((resolve, reject) => {
                fetch("/api/users", fetchOptions("GET"))
                    .then(res => res.json())
                    .then(json => {
                        resolve(json.map(user => ({
                            id: user.id,
                            data: [
                                {column: "firstName", value: user.first_name},
                                {column: "lastName", value: user.last_name},
                                {column: "company", value: user.company},
                                {column: "email", value: user.email},
                                {column: "phone", value: user.phone},
                                {column: "rep", value: user.rep_id},
                                {column: "region", value: user.user_region_id},
                                {column: "role", value: user.role}
                            ]
                        })));
                    })
                    .catch(reject);
            });
        }}
        create={(data, getColumn) => {
            return new Promise((resolve, reject) => {
                fetch("/api/users", fetchOptions("POST", {
                    firstName: getColumn("firstName"),
                    lastName: getColumn("lastName"),
                    company: getColumn("company"),
                    email: getColumn("email"),
                    phone: getColumn("phone"),
                    rep: getColumn("rep"),
                    region: getColumn("region"),
                    role: getColumn("role") || "user",
                }))
                    .then(res => (res.status === 200 ? resolve : reject)())
                    .catch(reject);
            });
        }}
        update={(id, data) => {
            return new Promise((resolve, reject) => {
                fetch("/api/users", fetchOptions("PATCH", {
                    id,
                    [data.column]: (data.column === "rep" ? parseInt(data.value) : data.value)
                }))
                    .then(resolve)
                    .catch(reject);
            });
        }}
        delete={id => {
            return new Promise((resolve, reject) => {
                fetch("/api/users", fetchOptions("DELETE", {id}))
                    .then(resolve)
                    .catch(reject);
            });
        }}
    />
);

export default Users;
