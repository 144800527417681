const fetchOptions = (method, body) => {
    let options = {
        method: method,
        credentials: "same-origin",
        headers: {

        }
    };

    if(body){
        options.headers["Content-Type"] = "application/json; charset=utf-8";
        options.body = JSON.stringify(body);
    }
    else{
        options.headers["Content-Type"] = "charset=utf-8";
        options.headers["pragma"] = "no-cache";
        options.headers["cache-control"] = "no-cache";
    }

    return options;
};

export default fetchOptions;