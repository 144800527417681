export default {

    /* -- ON SUCCESS -- */
    successRoute: "/login",
    successMsg: "Password reset successful, going back to login page...",

    /* -- TITLE -- */
    title:{
        text: "Password Reset",
        centered: true
    },

    /* -- INPUTS -- */
    inputs: [
        {
            name: "password",
            placeholder: "Password",
            type: "password",
            icon: require("../../img/icons/forms/password.svg"),
            required: true
        },
        {
            name: "verifyPassword",
            placeholder: "Verify Password",
            type: "password",
            icon: require("../../img/icons/forms/password.svg"),
            required: true
        }
    ],

    /* -- BUTTONS -- */
    buttons: [
        {
            text: "Reset password",
            type: "submit"
        }
    ]
};
