import React from "react";
import Paginator from "./Paginator";
import "font-awesome/css/font-awesome.css";
import FontAwesome from "react-fontawesome";
import fetchOptions from "../lib/fetch-options";
import columnTypes from "../lib/column-types";

// Configuration
const config = {
    perPage: 15,
    columnsToShrink: ["image", "select"],
    messages: {
        deleteRowConfirmation: "Are you sure?",
        invalidColumnType: "Invalid column type",
        textPlaceholder: "Enter a value...",
        defaultOption: "Select an item...",
        newImage: "New image URL:"
    }
};

class CrudTable extends React.Component {
    constructor(props) {
        super(props);

        // Default state
        this.state = {
            rows: [],
            columns: [],
            createRow: [],
            options: {},
            filter: "",
            filterTemp: "",
            filterOptions: {},
            page: 1,
            loading: true
        };

        fetch("/api/user/role", fetchOptions("GET"))
            .then(res => res.json())
            .then(res => {
                // If the user is not a super admin, filter out superAdminOnly columns
                if(res.role === "super-admin")
                    this.setState({columns: this.props.columns});
                else if (res.role === "national-admin")
                    this.setState({columns: this.props.columns ?
                            this.props.columns.filter(column =>
                                !column.superAdminOnly
                            ) : []});
                else
                    this.setState({columns: this.props.columns ?
                            this.props.columns.filter(column =>
                                !column.nationalAdminOnly &&
                                !column.superAdminOnly
                            ) : []});
            })
            .catch(console.error);

        this.config = config;
    }

    // Initial state setup
    componentDidMount() {
        this.read().then(() => {
            this.setState({loading: false}, this.initColumns);
        });
    }

    initColumns() {
        this.state.columns.forEach(column => {
            if (column.type === "select" || column.type === "readonly-select") {
                const isPromise = typeof(column.options.then) === "function";
                const options = isPromise ? column.options : Promise.resolve(column.options);
                options
                    .then(options => {
                        this.setState(prevState => {
                            let newState = prevState;
                            newState.options[column.name] = options;
                            return newState;
                        });
                    })
                    .catch(console.error);
            }
        });
        this.resetCreateRow();
    }

    // Reset the values of createRow
    resetCreateRow() {
        return new Promise((resolve, reject) => {
            this.setState({
                createRow: this.state.columns.map(col => ({
                    column: col.name,
                    value: ""
                }))
            }, resolve);
        });
    }

    // Read
    read() {
        return new Promise(resolve => {
            this.props.read()
                .then(rows => {

                    // Remove null rows
                    rows = rows.filter(row => row !== null);

                    rows.forEach(row => {

                        // Add a 'stringID' property to each row
                        row.stringID = JSON.stringify(row.id);

                        // 1) Remove fields that shouldn't exist
                        // 2) Add a 'disabled' property to each remaining field
                        let toRemove = [];
                        row.data.forEach((field, i) => {
                            if(this.state.columns.filter(column => column.name === field.column).length === 0)
                                toRemove.push(i);
                            else
                                field.disabled = false;
                        });
                        row.data = row.data.filter((_, i) => toRemove.indexOf(i) === -1);
                    });

                    // Set state of 'rows' array
                    this.setState({rows}, resolve);
                })
                .catch(console.error);
        });
    }

    // Create
    create() {
        return new Promise((resolve, reject) => {
            const getColumn = (column) => {
                const item = this.state.createRow.filter(x => x.column === column)[0];
                return item ? item.value : null;
            };
            this.props.create(this.state.createRow, getColumn)
                .then(() => this.read())
                .then(() => this.resetCreateRow())
                .then(resolve)
                .catch(reject);
        });
    }

    // Update
    update(id, data) {
        return new Promise(resolve => {
            this.props.update(id, data)
                .then(() => this.read())
                .then(resolve)
                .catch(console.error);
        });
    }

    // Delete
    del(id) {
        return new Promise(resolve => {
            if (window.confirm(config.messages.deleteRowConfirmation)) {
                this.props.delete(id)
                    .then(() => this.read())
                    .then(resolve)
                    .catch(console.error);
            }
            else {
                resolve();
            }
        });
    }

    renderHeaders() {
        // Render headers
        return this.state.columns
            .map(col =>
                <th
                    key={"key_" + col.name}
                    className={config.columnsToShrink.indexOf(col.type) !== -1 ? "shrink" : ""}
                >
                    {col.display}
                </th>
            )
            .concat(this.props.create && this.props.delete ? [<th key="action" className="action shrink">Action</th>] : [])
    }

    renderBody() {
        // Array of column names (for sorting fields)
        const colNames = this.state.columns.map(col => col.name);

        return this.renderRows(colNames)
    }

    filterRows() {
        return this.state.rows
            .filter(row => {
                const search = row.data
                    .map(data => {
                        let ret = "";
                        const options = this.state.options[data.column];
                        if(options){
                            const item = typeof options === 'object' && !(options instanceof Array) ?
                                options[row.id.regionID].filter(option => option.value === data.value)[0] :
                                options.filter(option => option.value === data.value)[0];
                            ret = item ? item.text.toLowerCase() : "";
                        }
                        else if(typeof data.value === "string"){
                            ret = data.value.toLowerCase();
                        }
                        return ret;
                    })
                    .map(string => string.indexOf(this.state.filter) !== -1)
                    .indexOf(true) !== -1;

                let matchesAllFilterOptions = true;
                row.data
                    .forEach(data => {
                        const filterKey = Object.keys(this.state.filterOptions).find(filterOption => data.column === filterOption);
                        if(
                            filterKey &&
                            this.state.filterOptions[filterKey] &&
                            data.value !== parseInt(this.state.filterOptions[filterKey])
                        ){
                            matchesAllFilterOptions = false;
                        }
                    });

                return search && matchesAllFilterOptions;
            })
    }

    renderRows(colNames) {
        return this.filterRows()
            .slice((this.state.page-1)*config.perPage, this.state.page*config.perPage)
            .concat(this.props.create ? [{
                isCreateRow: true,
                data: this.state.createRow
            }] : [])
            .map(row => {
                const fields = row.data
                    .sort((a, b) => {
                        if(colNames.indexOf(a.column) > colNames.indexOf(b.column))
                            return 1;
                        if(colNames.indexOf(a.column) === colNames.indexOf(b.column))
                            return 0;
                        return -1;
                    })
                    .map(field => <td key={"key_" + field.column}>{this.renderField({
                        field,
                        id: row.id,
                        stringID: row.stringID,
                        isCreateRow: row.isCreateRow
                    })}</td>)
                    .concat(this.props.create && this.props.delete ? [
                        row.isCreateRow ? (
                            <td key="create" className="create" onClick={() => this.create()}>Add</td>
                        ) : (
                            <td key="delete" className="delete" onClick={() => this.del(row.id)}>Delete</td>
                        )
                    ] : []);
                return <tr key={row.isCreateRow ? "create" : row.stringID}>{fields}</tr>;
            });
    }

    // Render a field
    renderField = ({id, stringID, field, isCreateRow}) => {
        // Column type
        const colType = this.state.columns.filter(col => col.name === field.column)[0].type;

        // Options
        const options = this.state.options[field.column] ? this.state.options[field.column] : [];

        let columnOptions;

        // Render field
        switch (colType) {

            // Read Only
            case "readonly":
                columnOptions = {
                    value: field.value
                };
                break;
            // Read Only Select
            case "readonly-select":
                columnOptions = {
                    options,
                    value: field.value
                };
                break;
            // Text
            case "text":
                columnOptions = {
                    id,
                    value: field.value,
                    field, stringID, isCreateRow,
                    placeholder: config.messages.textPlaceholder,
                    onChange: this.updateInternal,
                    onBlur: this.updateExternal
                };
                break;
            // Select
            case "select":
                columnOptions = {
                    id, options,
                    message: config.messages.defaultOption,
                    field, stringID, isCreateRow,
                    onChange: this.updateBoth
                };
                break;
            // Image
            case "image":
                columnOptions = {
                    id, field, stringID, isCreateRow,
                    updateBoth: this.updateBoth
                };
                break;
            // Default
            default:
                return config.messages.invalidColumnType;
        }
        return columnTypes[colType] ? columnTypes[colType](columnOptions) : `Column ${colType} not registered.`;
    };

    // Set field state
    setFieldState = ({key, value, field, stringID, isCreateRow}) => {
        const getFieldState = (stateObject) => {
            if (isCreateRow) {
                return stateObject.createRow
                    .filter(f => f.column === field.column)[0];
            }
            else {
                const rows = stateObject.rows.filter(row => row.stringID === stringID);
                if(rows[0])
                    return rows[0].data.filter(f => f.column === field.column)[0];
                else
                    return null;
            }
        };
        return new Promise(resolve => {
            this.setState(prevState => {
                let newState = prevState;
                const fieldState = getFieldState(newState);
                if(fieldState)
                    fieldState[key] = value;
                return newState;
            }, resolve);
        });
    };

    // Update internal
    updateInternal = ({value, field, stringID, isCreateRow}) => {
        return new Promise((resolve, reject) => {
            this.setFieldState({key: "value", value, field, stringID, isCreateRow})
                .then(resolve)
                .catch(reject);
        });
    };

    // Update external
    updateExternal = ({id, value, field, stringID, isCreateRow}) => {
        if (!isCreateRow) {
            this.setFieldState({key: "disabled", value: true, field, stringID, isCreateRow});
            this.update(id, {
                column: field.column,
                value
            })
                .then(() => {
                    this.setFieldState({key: "disabled", value: false, field, stringID, isCreateRow});
                })
                .catch(console.error);
        }
    };

    // Update both
    updateBoth = ({id, value, field, stringID, isCreateRow}) => {
        this.updateInternal({value, field, stringID, isCreateRow})
            .then(() => {this.updateExternal({id, value, field, stringID, isCreateRow})})
            .catch(console.error);
    };

    Pages() {
        return (
            <div style={{display: "flex", margin: "10px 0"}}>
                <div style={{flexGrow: "1"}}>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        this.setState({
                            filter: this.state.filterTemp.toLowerCase(),
                            page: 1
                        });
                    }}>
                        <input
                            type="text"
                            placeholder="Search"
                            value={this.state.filterTemp}
                            onChange={e => {
                                this.setState({filterTemp: e.target.value});
                            }}/>
                        <input type="submit" value="Update" style={{margin: "0 5px"}}/>
                        {this.state.columns.map(column => {
                            if(column.createFilter){
                                const options = this.state.options[column.name] ? this.state.options[column.name] : [];
                                const defaultOption = <option key="default" value="">{config.messages.defaultOption}</option>;
                                const selectOptions = column.prependedFilterOptions
                                    .concat(options)
                                    .map(option => (<option key={"key_" + option.value} value={option.value}>{option.text}</option>));

                                return (
                                    <span style={{marginLeft: "2em"}} key={column.name}>
                                        Filter By {column.display}: <select
                                        value={this.state.filterOptions[column.name] || ""}
                                        onChange={e => {
                                            const value = e.target.value;
                                            this.setState(prevState => {
                                                let newState = prevState;
                                                newState.filterOptions[column.name] = value;
                                                return newState;
                                            });
                                        }}
                                    >
                                            {defaultOption}
                                        {selectOptions}
                                        </select>
                                    </span>
                                );
                            }
                            else return null;
                        })}
                    </form>
                </div>
                <Paginator
                    value={this.state.page}
                    onChange={newValue => this.setState({page: newValue})}
                    max={Math.ceil(this.state.rows.length / config.perPage)}/>
            </div>
        );
    }

    // Render
    render() {

        // Spinner
        if(this.state.loading){
            return (
                <div style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center"
                }}>
                    <div style={{
                        flex: "1",
                        textAlign: "center"
                    }}>
                        <FontAwesome name='cog' size='5x' spin/>
                    </div>
                </div>
            );
        }

        // Render table
        return (
            <div>
                {this.Pages()}
                <table id="admin-crud-table">
                    <thead>
                    <tr>
                        {this.renderHeaders()}
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderBody()}
                    </tbody>
                </table>
                <br/>
            </div>
        );
    }
}

export default CrudTable;
