import React from 'react';

import {ListTable} from './admin-tools';
import {closersUrl} from './lib/list-props';

const Closers = () => (
    <ListTable
        title="Closers"
        endpoint={closersUrl}
    />
);

export default Closers;
