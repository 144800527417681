import "./style.scss";

import React from "react";
import {render} from "react-dom";
import {BrowserRouter} from "react-router-dom";

import App from "./App";

const tokenIntervalSeconds = 60;

const getToken = () => {
    return new Promise((resolve, reject) => {
        fetch("/api/refresh-token", {
            credentials: "same-origin",
            headers: {"Content-Type": "application/json; charset=utf-8"}
        })
            .then(res => res.json())
            .then(token => {
                document.cookie = `antiSpamToken=${token};path=/`;
                resolve();
            })
            .catch(reject)
    });
};

const renderPage = () => {
    render(
        <BrowserRouter>
            <App/>
        </BrowserRouter>,
        document.getElementById("root")
    );

    if(module.hot)
        module.hot.accept();

    setInterval(getToken, tokenIntervalSeconds * 1000);
};

getToken().then(renderPage).catch(console.error);
