import fetchOptions from "../../../Admin/lib/fetch-options";

const getClosers = () => {
    return new Promise((resolve, reject) => {
        fetch("/api/closers-list?only=names", fetchOptions("GET"))
            .then(res => res.json())
            .then(resolve)
            .catch(reject);
    });
};

export default {

    /* -- ON SUCCESS -- */
    successMsg: "Thank you, we have received your request for a call! Your Escrow Officer will call you within 15 minutes during business hours. If it is after business hours, you will receive a call first thing the very next business day.",
    successRoute: "/",

    /* -- TITLE -- */
    title: {
        text: "My Closer",
        centered: true
    },

    /* -- INPUTS -- */
    inputs: [
        {
            name: "closer",
            placeholder: "Closer",
            type: "select",
            options: getClosers,
            required: true
        },
        {
            name: "notes",
            placeholder: "Notes",
            type: "textarea",
            required: false
        }
    ],

    /* -- BUTTONS -- */
    buttons: [
        {
            text: "Submit",
            type: "submit"
        }
    ]
};
