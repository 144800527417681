import React from "react";
import Dropzone from "react-dropzone";

const imageTest = /\.(jpe?g|png|gif|svg)$/;
const nonImagePreview = require("../../img/document.png");

class Uploads extends React.Component {
    constructor() {
        super();
        this.state = {
            newFiles: [],
            currentFiles: []
        };
    }

    componentDidMount() {
        this.getCurrentFiles();
    }

    getCurrentFiles() {
        fetch(this.props.endpoint, {
            headers: this.props.headers,
            credentials: "same-origin"
        })
        .then(res => res.json())
        .then(currentFiles => this.setState({currentFiles}))
    }

    submitFiles(){
        const data = new FormData();
        this.state.newFiles.forEach(file => data.append("file", file));
        fetch(this.props.endpoint, {
            method: "POST",
            body: data,
            credentials: "same-origin",
            headers: this.props.headers
        }).then(() => {
            this.getCurrentFiles();
            this.clearFiles();
        });
    }

    clearFiles(){
        this.setState({newFiles: []});
    }

    deleteFile(filename){
        if(window.confirm("Are you sure you want to delete " + filename + "?")){
            fetch(this.props.endpoint, {
                method: "DELETE",
                credentials: "same-origin",
                headers: Object.assign({}, {"Content-Type": "application/json"}, this.props.headers),
                body: JSON.stringify({filename})
            }).then(() => {
                this.getCurrentFiles();
            });
        }
    }

    render() {

        // On file drop
        const onDrop = (accepted, rejected) => {
            this.setState(prevState => {
                return {
                    newFiles: prevState.newFiles.concat(accepted)
                };
            });
        };

        // Dropzone content
        const dropzoneContent = this.state.newFiles.length === 0 ? (
            <div>Drop files here (or click me) to upload.</div>
        ) : (
            this.state.newFiles.map(file => <div key={file.name}>{file.name}</div>)
        );

        // Currently uploaded files
        const currentFiles = this.state.currentFiles.map(file => {
            const imgSrc = imageTest.test(file.name) ? file.url: nonImagePreview;
            return (
                <div className="image-wrapper" key={file.name}>
                    <a href={file.url} target="_blank" rel="noopener noreferrer">
                        <img src={imgSrc} alt="uploaded file"/>
                        <div className="ellipsis">{file.name}</div>
                    </a>
                    <div className="overlay">
                        <button type="button" onClick={() => this.deleteFile(file.name)}>
                            X
                        </button>
                    </div>
                </div>
            );
        });

        // Render
        return (
            <div id="admin-uploads">
                <Dropzone className="dropzone" onDrop={onDrop}>
                    {dropzoneContent}
                </Dropzone>
                <br/>
                <div>
                    <button type="button" onClick={this.submitFiles.bind(this)}>Upload</button>
                    <button type="button" onClick={this.clearFiles.bind(this)}>Clear</button>
                </div>
                <br/>
                <div id="current-files">{currentFiles}</div>
            </div>
        );
    }
};

export default Uploads;