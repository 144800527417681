export default {

    /* -- ON SUCCESS -- */
    successRoute: "/login",
    successMsg: "Please check your email for a link to reset your password.",

    /* -- TITLE -- */
    title:{
        text: "Password Reset",
        centered: true
    },

    /* -- INPUTS -- */
    inputs: [
        {
            name: "email",
            placeholder: "Email",
            type: "email",
            icon: require("../../img/icons/forms/email.svg"),
            required: true
        }
    ],

    /* -- BUTTONS -- */
    buttons: [
        {
            text: "Send Email",
            type: "submit"
        }
    ],

    /* -- LINKS -- */
    links: [
        {
            text: "Go back to login form.",
            url: "/login",
            share: ["email"]
        }
    ]
};
