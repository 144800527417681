import "./style.scss";

import React from "react";
import {Switch, Route, Link, Redirect} from "react-router-dom";
import fetchOptions from "../../Admin/lib/fetch-options";

class Widgets extends React.Component {
    constructor(props) {
        super(props);
        const { widgetData, userData, repData } = this.props;
        this.state = {
            widgetData,
            userData,
            repData,
            links: [],
            hrefs: {},
            positions: []
        };
    }

    componentWillMount() {
        this.initWidgets();
        this.getLinks();
        this.getWidgetOrder();
    }

    componentDidMount() {
    }

    initWidgets = () => {
        this.setState({
            hrefs: this.state.widgetData.reduce((a, c) => {
                if(c.type === "link") a[c.id] = "#";
                return a;
            }, {})
        })
    };

    getWidgetOrder = () => {
        fetch("/api/widget-order?groupByRegion=true", fetchOptions("GET"))
            .then(res => res.json())
            .then(widgetGroups => {
                const filteredWidgetGroups = widgetGroups.filter(x => x.region_id === this.state.repData.region_id);
                const positions = filteredWidgetGroups.length > 0 ? filteredWidgetGroups[0].positions : [];

                let orderedWidgets = this.state.widgetData.map(() => '');
                positions.forEach(p => {
                    const widget = this.state.widgetData.find(w => w.id === p.widget_id);
                    const position = Number(p.position);
                    if(!!position && position > 0)
                        orderedWidgets[position - 1] = widget;
                });
                const leftoverWidgets = this.state.widgetData.filter(w => !orderedWidgets.find(ow => ow && ow.id === w.id));
                orderedWidgets = orderedWidgets.map(ow => !!ow ? ow : leftoverWidgets.shift());

                this.setState(state => ({...state, positions: orderedWidgets}));
            })
            .catch(console.error)
    };

    getLinks = () => {
        fetch("/api/links?groupByRegion=true", fetchOptions("GET"))
            .then(res => res.json())
            .then(linkGroups => {
                const filteredLinkGroups = linkGroups.filter(x => x.region_id === this.state.repData.region_id);
                const links = filteredLinkGroups.length > 0 ? filteredLinkGroups[0].links : [];
                this.setState({links});

                this.state.widgetData.forEach(widget => {
                    if(widget.type !== "link") return;

                    const widgetObj = links.filter(x => x.widget_id === widget.id)[0];
                    const linkObj = widgetObj ? widgetObj.link : {};

                    if(widget.linkCB) {
                        widget.linkCB(linkObj, {user: this.state.userData})
                            .then(href => {
                                this.setState(prevState => {
                                    let newState = prevState;
                                    newState.hrefs[widget.id] = href;
                                    return newState;
                                });
                            })
                            .catch(console.error);
                    } else {
                        this.setState(prevState => {
                            let newState = prevState;
                            newState.hrefs[widget.id] = linkObj[""];
                            return newState;
                        });
                    }
                });
            })
            .catch(console.error);
    };

    render() {
        const addVars = (str) => {
            return str
                .replace("$NAME", this.state.repData.name)
                .replace("$REGION", this.state.repData.region);
        };

        const widgets = this.state.positions
            .reduce((acc, widget) => {
                let content = null;
                if(!widget) return acc;

                switch(widget.type) {
                    case "link":
                        content = (
                            <a href={this.state.hrefs[widget.id]}
                               target={this.state.hrefs[widget.id] === "#" ? "" : "_blank"} rel="noopener noreferrer">
                                <div>
                                    <img src={widget.icon} alt="link"/>
                                    <div>{addVars(widget.title)}</div>
                                </div>
                            </a>
                        );
                        break;

                    case "render":
                        content = (
                            <Link to={widget.route}>
                                <div>
                                    <img src={widget.icon} alt="render"/>
                                    <div>{addVars(widget.title)}</div>
                                </div>
                            </Link>
                        );
                        break;

                    case "action":
                        content = (
                            // eslint-disable-next-line
                            <a href="#" onClick={widget.callback}>
                                <div>
                                    <img src={widget.icon} alt="action"/>
                                    <div>{addVars(widget.title)}</div>
                                </div>
                            </a>
                        );
                        break;
                    // no default
                }

                acc.push(
                    <div className="widget" key={widget.id} onClick={() => {
                        fetch("/api/analytics", fetchOptions("POST", {
                            eventName: widget.name || widget.title
                        })).then().catch();
                    }}>
                        {content}
                    </div>
                );
                return acc;
            }, []);

        const routes = this.state.widgetData
            .filter(widget => widget.type === "render")
            .map(widget => (
                <Route path={widget.route} key={widget.id} render={() => (
                    <div className="invert" style={{height: "100%"}}>
                        <Link to="/">
                            <img src={require("../../img/arrow.png")} alt="Route"
                                 style={{height: "40px", margin: "30px"}}/>
                        </Link>
                        <div>{widget.callback(this.state.repData)}</div>
                    </div>
                )}/>
            ));

        return (
            <Switch>
                <Route exact path="/" render={() => (
                    <div>
                        <div id="widgets">
                            {widgets}
                        </div>
                        <div id="logout">
                            <a href="/?logout=true" style={{fontStyle: "italic"}}>Click here to logout.</a>
                        </div>
                    </div>
                )}/>
                {routes}
                <Route render={() => (
                    <Redirect to="/"/>
                )}/>

            </Switch>
        );
    }
}

export default Widgets;