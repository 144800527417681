import React from "react";

import {CrudTable} from "./admin-tools";
import fetchOptions from "./lib/fetch-options";

const Reps = () => (
    <CrudTable
        columns={[
            {
                name: "img",
                display: "Profile Image",
                type: "image"
            },
            {
                name: "name",
                display: "Name",
                type: "text"
            },
            {
                name: "title",
                display: "Title",
                type: "text"
            },
            {
                name: "url",
                display: "URL",
                type: "text"
            },
            {
                name: "region",
                display: "Region",
                type: "select",
                nationalAdminOnly: true,
                options: new Promise((resolve, reject) => {
                    fetch("/api/regions", fetchOptions("GET"))
                        .then(res => res.json())
                        .then(json => {
                            resolve(json.map(region => ({
                                value: region.id,
                                text: region.name
                            })));
                        })
                        .catch(reject);
                })
            },
            {
                name: "email",
                display: "Email",
                type: "text"
            },
            {
                name: "phone",
                display: "Phone Number",
                type: "text"
            },
            {
                name: "website",
                display: "Website",
                type: "text"
            },
            {
                name: "instagram",
                display: "Instagram",
                type: "text"
            },
            {
                name: "linkedin",
                display: "LinkedIn",
                type: "text"
            },
            {
                name: "facebook",
                display: "Facebook",
                type: "text"
            },
            {
                name: "address1",
                display: "Address Line 1",
                type: "text"
            },
            {
                name: "address2",
                display: "Address Line 2",
                type: "text"
            },
            {
                name: "city",
                display: "City",
                type: "text"
            },
            {
                name: "state",
                display: "State",
                type: "text"
            },
            {
                name: "zipcode",
                display: "Zipcode",
                type: "text"
            }
        ]}
        read={() => {
            return new Promise((resolve, reject) => {
                fetch("/api/reps", fetchOptions("GET"))
                    .then(res => res.json())
                    .then(json => {
                        resolve(json.map(rep => ({
                            id: rep.id,
                            data: [
                                {column: "img", value: rep.img},
                                {column: "name", value: rep.name},
                                {column: "title", value: rep.title},
                                {column: "url", value: rep.rep_url},
                                {column: "region", value: rep.region_id},
                                {column: "email", value: rep.email},
                                {column: "phone", value: rep.phone},
                                {column: "website", value: rep.website},
                                {column: "instagram", value: rep.instagram},
                                {column: "linkedin", value: rep.linkedin},
                                {column: "facebook", value: rep.facebook},
                                {column: "address1", value: rep.address1},
                                {column: "address2", value: rep.address2},
                                {column: "city", value: rep.city},
                                {column: "state", value: rep.state},
                                {column: "zipcode", value: rep.zipcode}
                            ]
                        })));
                    })
                    .catch(reject);
            });
        }}
        create={(data, getColumn) => {
            return new Promise((resolve, reject) => {
                const createRep = (region) => {
                    fetch("/api/reps", fetchOptions("POST", {
                        img: getColumn("img"),
                        name: getColumn("name"),
                        title: getColumn("title"),
                        url: getColumn("url"),
                        region: region,
                        email: getColumn("email"),
                        phone: getColumn("phone"),
                        website: getColumn("website"),
                        instagram: getColumn("instagram"),
                        linkedin: getColumn("linkedin"),
                        facebook: getColumn("facebook"),
                        address1: getColumn("address1"),
                        address2: getColumn("address2"),
                        city: getColumn("city"),
                        state: getColumn("state"),
                        zipcode: getColumn("zipcode")
                    }))
                        .then(res => (res.status === 200 ? resolve : reject)())
                        .catch(reject);
                };

                if(getColumn("region")){
                    createRep(getColumn("region"));
                }
                else{
                    fetch("/api/regions", fetchOptions("GET"))
                        .then(res => res.json())
                        .then(regions => {
                            createRep(regions[0].id);
                        })
                        .catch(reject);
                }
            });
        }}
        update={(id, data) => {
            return new Promise((resolve, reject) => {
                fetch("/api/reps", fetchOptions("PATCH", {
                    id,
                    [data.column]: (data.column === "region" ? parseInt(data.value) : data.value)
                }))
                    .then(resolve)
                    .catch(reject);
            });
        }}
        delete={id => {
            return new Promise((resolve, reject) => {
                fetch("/api/reps", fetchOptions("DELETE", {id}))
                    .then(resolve)
                    .catch(reject);
            });
        }}
    />
);

export default Reps;
