import FontAwesome from 'react-fontawesome';
import React from 'react';
import { CrudTable } from './index';
import fetchOptions from '../lib/fetch-options';
import columnTypes from '../lib/column-types';
import widgetData from '../../Main/widget-data';

class WOCrudTable extends CrudTable {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            widgets: [],
            regions: [],
            widgetOrder: [],
            positions: [],
            regionSelection: null
        };
    }

    componentDidMount() {
        this.initWidgets();
    }

    initWidgets() {
        this.setState({ loading: true });
        Promise.all([
            '/api/widget-order',
            '/api/regions',
            '/api/widgets'
        ].map((url) => fetch(url, fetchOptions('GET')).then((res) => res.json())))
            .then((result) => {
                const [widgetOrder, regions, widgets] = result;
                let regionSelection = null;
                if (regions.length === 1) regionSelection = regions[0].id;
                this.setState((prevState) => ({
                    ...prevState,
                    widgetOrder,
                    regions,
                    widgets,
                    regionSelection: prevState.regionSelection
                        ? prevState.regionSelection : regionSelection
                }), this.reset);
            })
            .catch(console.error);
    }

    setOptions(column) {
        const isPromise = typeof (column.options.then) === 'function';
        const options = isPromise ? column.options : Promise.resolve(column.options);
        options
            .then((option) => {
                this.setState((prevState) => {
                    const nextState = prevState;
                    nextState.options[column.name] = option;
                    return nextState;
                });
            })
            .catch(console.error);
    }

    reset() {
        this.reloadOptions();
        this.read();
        this.setState({ loading: false });
        this.resetCreateRow();
        this.setState((prevState) => {
            const regionSelection = Number(prevState.regionSelection);
            return {
                ...prevState,
                regionSelection: regionSelection && regionSelection > 0 ? regionSelection : 0
            };
        }, this.orderWidgets);
    }

    reloadOptions() {
        // Resolve promises for options in 'select' type columns
        this.state.columns.forEach((column) => {
            if (['select', 'readonly-select'].includes(column.type)) {
                this.setOptions(column);
            } else if (column.type === 'widget-order') {
                this.setState((prevState) => {
                    const nextState = prevState;
                    nextState.options[column.name] = column.options({
                        widgets: prevState.widgets,
                        widgetOrder: prevState.widgetOrder
                    });
                    return nextState;
                });
            }
        });
    }

    read() {
        const { widgets, regions, widgetOrder } = this.state;
        let rows = this.props.read({
            position: widgetOrder,
            regions,
            regionWidgets: widgets
        });

        // Remove null rows
        rows = rows.filter((row) => row !== null);

        rows.forEach((row) => {
            // Add a 'stringID' property to each row
            row.stringID = JSON.stringify(row.id);

            // 1) Remove fields that shouldn't exist
            // 2) Add a 'disabled' property to each remaining field
            const toRemove = [];
            row.data.forEach((field, i) => {
                if (this.state.columns.filter((column) =>
                    column.name === field.column).length === 0) toRemove.push(i);
                else field.disabled = false;
            });
            row.data = row.data.filter((_, i) => toRemove.indexOf(i) === -1);
        });

        // Set state of 'rows' array
        this.setState({ rows });
    }

    // force an index for each widget that has a position index stored in DB,
    // then fill the remaining around them
    orderWidgets() {
        const { widgets, widgetOrder } = this.state;
        const regionID = Number(this.state.regionSelection);
        const positions = widgetOrder.filter((x) => x.region_id === regionID);
        const filteredWidgets = widgets.filter((x) => x.region_id === regionID);
        const filteredWidgetData = widgetData
            .filter((wd) => filteredWidgets.find((fw) => fw.widget_id === wd.id));
        let orderedWidgets = filteredWidgetData.map(() => '');
        positions.forEach((p) => {
            const widget = widgetData.find((w) => w.id === p.widget_id);
            const position = Number(p.position);
            if (!!position && position > 0) orderedWidgets[position - 1] = widget;
        });
        const leftoverWidgets = filteredWidgetData
            .filter((w) => !orderedWidgets.find((ow) => ow && ow.id === w.id));
        orderedWidgets = orderedWidgets.map((ow) => ow || leftoverWidgets.shift());

        this.setState((state) => ({ ...state, positions: orderedWidgets }));
    }

    del(id) {
        return new Promise((resolve) => {
            this.props.delete(id)
                .then(() => this.read())
                .then(resolve)
                .catch(console.error);
        });
    }

    renderField = ({id, stringID, field, isCreateRow}) => {
            // Column type
            const colType = this.state.columns.filter(col => col.name === field.column)[0].type;

            // Options
            const options = this.state.options[field.column] ? this.state.options[field.column] : [];

            let columnOptions;

            // Render field
            switch (colType) {
                // Read Only
                case "readonly":
                    columnOptions = {
                        value: field.value
                    };
                    break;
                // Select Filtered
                case 'widget-order':
                    columnOptions = {
                        id,
                        value: field.value,
                        disabled: field.disabled,
                        field, stringID, options, isCreateRow,
                        onChange: this.updateBothReload,
                        defaultOption: this.config.messages.defaultOption
                    };
                    break;
                // Text
                case "text":
                    columnOptions = {
                        id,
                        value: field.value,
                        field, stringID, isCreateRow,
                        placeholder: this.config.messages.textPlaceholder,
                        onChange: this.updateInternal,
                        onBlur: this.updateExternal
                    };
                    break;
                // Default
                default:
                    return this.config.messages.invalidColumnType;
            }
            return columnTypes[colType](columnOptions);
    };

    updateBothReload = ({id, value, field, stringID, isCreateRow}) => {
        this.updateInternal({value, field, stringID, isCreateRow})
            .then(() => {
                if (!isCreateRow) {
                    this.setFieldState({key: "disabled", value: true, field, stringID, isCreateRow});
                    const restCall = value
                        ? this.update(id, {
                            column: field.column,
                            value: field.value
                        })
                        : this.del(id);
                    restCall
                        .then(() => {
                            this.initWidgets();
                            this.setFieldState({key: "disabled", value: false, field, stringID, isCreateRow});
                        })
                        .catch(console.error);
                }
            })
            .catch(console.error);
    };

    renderRows(colNames) {
        return this.filterRows()
            .slice((this.state.page-1)*this.config.perPage, this.state.page*this.config.perPage)
            .map(row => {
                const fields = row.data
                    .sort((a, b) => {
                        if(colNames.indexOf(a.column) > colNames.indexOf(b.column))
                            return 1;
                        if(colNames.indexOf(a.column) === colNames.indexOf(b.column))
                            return 0;
                        return -1;
                    })
                    .map(field => <td key={"key_" + field.column}>{this.renderField({
                        field,
                        id: row.id,
                        stringID: row.stringID
                    })}</td>);
                return <tr key={row.stringID}>{fields}</tr>;
            });
    }

    render() {
        // Spinner
        if (this.state.loading) {
            return (
                <div style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center'
                }}
                >
                    <div style={{
                        flex: '1',
                        textAlign: 'center'
                    }}
                    >
                        <FontAwesome name="cog" size="5x" spin />
                    </div>
                </div>
            );
        }

        const addVars = (str) => str
            .replace('$NAME', '[Rep Name]')
            .replace('$REGION', this.state.regions.find((r) => this.state.regionSelection === r.id).name);

        const widgets = this.state && this.state.regionSelection > 0
            ? this.state.positions.reduce((acc, widget, index) => {
                if(!widget) return acc;
                let el = (
                    <div key={`${widget.title}${index}`} className="widget-icon">
                        <div className="position">{index + 1}</div>
                        <div className="icon-title">
                            <img src={widget.icon} alt="link" />
                            <div>{addVars(widget.title)}</div>
                        </div>
                    </div>
                );
                acc.push(el)
                return acc;
            }, []) : [];

        // Render table
        return (
            <div>
                {this.Pages()}
                <table id="admin-crud-table">
                    <thead>
                        <tr>
                            {this.renderHeaders()}
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderBody()}
                    </tbody>
                </table>
                <br />
                <div id="view-preview">
                    <a href="#preview"><button type="button">View Preview</button></a>
                </div>
                <div id="preview">
                    <h1>Preview</h1>
                    <select
                        defaultValue={this.state.regionSelection ? this.state.regionSelection : 0}
                        onChange={(e) =>
                            this.setState({
                                regionSelection: Number(e.target.value)
                            }, this.orderWidgets)}
                    >
                        <option value={0}>Select a region...</option>
                        {
                            this.state.regions && this.state.regions.map((r) => (
                                <option key={`${r.id}${r.name}`} value={r.id}>{r.name}</option>
                            ))
                        }
                    </select>
                    <div id="widget-preview">
                        {widgets}
                    </div>
                </div>
            </div>
        );
    }
}

export default WOCrudTable;
