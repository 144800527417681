import React from 'react';
import 'font-awesome/css/font-awesome.css';
import FontAwesome from 'react-fontawesome';
import Paginator from './Paginator';
import fetchOptions from '../lib/fetch-options';

const config = {
    perPage: 12
};

const toKeyString = (str, index) => `${str}${index}`;
const initialState = () => ({
    new_name: '',
    new_email: '',
});

class ListTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            endpoint: props.endpoint,
            items: [],
            new_name: '',
            new_email: '',
            loading: true,
            page: 1
        };

        this.api = (method, body) => new Promise((resolve, reject) => {
            fetch(this.state.endpoint, fetchOptions(method, body))
                .then((res) => method === "GET" ? res.json() : res)
                .then(resolve)
                .catch(reject);
        });
    }

    componentDidMount() {
        this.read().catch(console.error);
    }

    setLoading = (loading) => {
        this.setState({ loading });
    };

    read = () => new Promise((resolve, reject) => {
        this.api('GET')
            .then((items) => {
                this.setState({ items, loading: false });
                resolve(items);
            })
            .catch(reject);
    });

    update = (name, email) => new Promise(((resolve, reject) => {
        this.setLoading(true);
        this.api('POST', { name, email })
            .then((res) => {
                this.read().then(() => resolve(res)).catch(console.error);
            })
            .catch(reject);
    }));

    del = (id, name, email) => new Promise(((resolve, reject) => {
        this.setLoading(true);
        this.api('DELETE', { id, name, email })
            .then((res) => this.read().then(() => resolve(res)).catch(console.error))
            .catch(reject);
    }));

    submitName = (event) => {
        event.preventDefault();
        this.update(this.state.new_name, this.state.new_email)
            .then(() => this.setState(initialState()))
            .catch(console.error);
    };

    updateNewName = (event) => {
        this.setState({ new_name: event.target.value });
    };
    
    updateNewEmail = (event) => {
        this.setState({ new_email: event.target.value });
    };

    handleDelete({ id, name, email }) {
        this.del(id, name, email).catch(console.error);
    }

    render() {
        const { items } = this.state;
        // Spinner
        if (this.state.loading) {
            return (
                <div style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center'
                }}
                >
                    <div style={{
                        flex: '1',
                        textAlign: 'center'
                    }}
                    >
                        <FontAwesome name="cog" size="5x" spin />
                    </div>
                </div>
            );
        }

        const slicedIndexes = {
            start: (this.state.page - 1) * config.perPage,
            end: this.state.page * config.perPage
        };

        return (
            <div>
                <h1>{this.state.title}</h1>
                <div id="list-main">
                    <div className="section submission">
                        <form onSubmit={this.submitName}>
                            <input
                                type="text"
                                placeholder="Enter a name..."
                                onChange={this.updateNewName}
                                value={this.state.new_name}
                            />
                            <input
                                type="text"
                                placeholder="Enter an email..."
                                onChange={this.updateNewEmail}
                                value={this.state.new_email}
                            />
                            <button type="submit">+</button>
                        </form>
                    </div>
                    <div className="section list">
                        <Paginator
                            value={this.state.page}
                            onChange={(newValue) => this.setState({ page: newValue })}
                            max={Math.ceil(this.state.items.length / config.perPage)}
                        />
                        {items && !!items.length
                            && items.slice(slicedIndexes.start, slicedIndexes.end)
                                .map((item, index) => (
                                    <div className="item" key={toKeyString(item, index)}>
                                        <button type="button" onClick={() => this.handleDelete(item)}>
                                            -
                                        </button>
                                        <div>{item.name} - {item.email}</div>
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ListTable;
