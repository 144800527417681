export default (title) => {
    return {

        /* -- ON SUCCESS -- */
        successMsg: "Success! Your rep has been notified.",
        successRoute: "/",

        /* -- TITLE -- */
        title:{
            text: title,
            centered: false
        },

        /* -- INPUTS -- */
        inputs: [
            {
                name: "datetime",
                placeholder: "Date / Time",
                type: "date",
                required: true,
                time: true
            },
            {
                name: "message",
                placeholder: "Note",
                type: "textarea",
                required: true
            }
        ],

        /* -- BUTTONS -- */
        buttons: [
            {
                text: "Submit",
                type: "submit"
            }
        ]
    };
};
