import React from "react";

class JoinTable extends React.Component {
    constructor(props) {
        super(props);

        // Default state
        this.state = {
            rows: [],
            columns: [],
            joins: []
        };

        // Set state for rows / columns on promise resolution
        ["rows", "columns"].forEach(x => {
            props[x]()
                .then(data => {
                    this.setState({[x]: data});
                })
                .catch(console.error);
        });
    }

    // Initial read
    componentDidMount() {
        this.read();
    }

    // Read
    read() {
        this.props.read()
            .then(joins => {
                this.setState({joins});
            })
            .catch(console.error);
    }

    // Update
    update(row, column, state) {
        this.props.update(row, column, state)
            .then(() => this.read())
            .catch(console.error);
    }

    // Render
    render() {

        // Render headers
        const renderHeaders = () => {
            return ([<th key="empty"></th>]).concat(
                this.state.columns.map(column =>
                    <th
                        key={"key_" + column.value}
                        className="shrink"
                    >
                        {column.text}
                    </th>
                )
            );
        };

        // Render body
        const renderBody = () => {
            return this.state.rows.map(row => {
                const fields = ([<td key="value">{row.text}</td>]).concat(
                    this.state.columns.map(column => {
                        const isChecked = this.state.joins.filter(x => x.row === row.value && x.column === column.value).length > 0;
                        const onChange = (e) => this.update(row.value, column.value, e.target.checked);
                        return (
                            <td key={"key_" + column.value}>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={onChange}/>
                            </td>
                        );
                    })
                );
                return <tr key={row.value}>{fields}</tr>
            });
        };

        // Render table
        return (
            <table id="admin-join-table">
                <thead>
                    <tr>
                        {renderHeaders()}
                    </tr>
                </thead>
                <tbody>
                    {renderBody()}
                </tbody>
            </table>
        );
    }
}

export default JoinTable;