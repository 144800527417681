import React from "react";

import "font-awesome/css/font-awesome.css";
import FontAwesome from "react-fontawesome";

const iconStyle = {
    margin: "10px"
};

const textStyle = {
    margin: "0 20px",
};

const wrapperStyle = {
    fontSize: "2em",
    cursor: "pointer"
};

class Paginator extends React.Component{
    decrement(){
        if(this.props.value > 1)
            this.props.onChange(this.props.value - 1);
    }

    increment(){
        if(this.props.value < this.props.max)
            this.props.onChange(this.props.value + 1);
    }

    render(){
        return (
            <div style={wrapperStyle}>
                <FontAwesome name="arrow-left" style={iconStyle} onClick={this.decrement.bind(this)}/>
                <span style={textStyle}>{this.props.value}</span>
                <FontAwesome name="arrow-right" style={iconStyle} onClick={this.increment.bind(this)}/>
            </div>
        );
    }
}

export default Paginator;
