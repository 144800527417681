export default {

    /* -- ON SUCCESS -- */
    successRoute: "/",

    /* -- TITLE -- */
    title:{
        centered: true
    },

    /* -- INPUTS -- */
    inputs: [
        {
            name: "email",
            placeholder: "Email",
            type: "email",
            icon: require("../../img/icons/forms/email.svg"),
            required: true
        },
        {
            name: "password",
            placeholder: "Password",
            type: "password",
            icon: require("../../img/icons/forms/password.svg")
        }
    ],

    /* -- BUTTONS -- */
    buttons: [
        {
            text: "Login",
            type: "submit"
        },
        {
            text: "I Forgot My Password",
            type: "link",
            url: "/reset-password",
            share: ["email"]
        }
    ],

    /* -- LINKS -- */
    links: [
        {
            text: "Don't have an account? Click here.",
            url: "/register",
            share: ["email"]
        }
    ]
};
