export default {

    /* -- ON SUCCESS -- */
    successMsg: "Thanks - Your order is in!",
    successRoute: "/",

    /* -- TITLE -- */
    title: {
        text: "Order Homebook",
        centered: true
    },

    /* -- INPUTS -- */
    inputs: [
        {
            name: "ordertitle",
            placeholder: "Order Title",
            type: "checkbox"
        },
        {
            name: "owner",
            placeholder: "Owner",
            type: "text",
            required: true
        },
        {
            name: "parcelNumber",
            placeholder: "Parcel Number",
            type: "text",
            required: true
        },
        {
            name: "address",
            placeholder: "Address",
            type: "text",
            required: true
        },
        {
            name: "city",
            placeholder: "City",
            type: "text",
            required: true
        },
        {
            name: "state",
            placeholder: "State",
            type: "text",
            required: true
        },
        {
            name: "zipcode",
            placeholder: "Zipcode",
            type: "text",
            required: true
        },
        {
            name: "county",
            placeholder: "County",
            type: "text",
            required: true
        },
    ],

    /* -- BUTTONS -- */
    buttons: [
        {
            text: "Submit",
            type: "submit"
        }
    ]
};
