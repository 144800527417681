import widgetData from "../../Main/widget-data";
import fetchOptions from "./fetch-options";

const mapToRegionWidgets = (endpoint, key, widgetCondition) => {
    return new Promise((resolve, reject) => {
        Promise.all(
            [
                endpoint,
                "/api/regions",
                "/api/widgets"
            ]
                .map(endpoint =>
                    fetch(endpoint, fetchOptions("GET"))
                        .then(res => res.json())
                        .catch(reject)
                )
        )
            .then(res => {
                const data = res[0];
                const regions = res[1];
                const widgets = res[2];

                let ret = [];

                regions.forEach(region =>
                    ret.push(...widgetData.map(widget => {
                            if(!widgetCondition(widget))
                                return null;

                            const itemExists = item => item.region_id === region.id && item.widget_id === widget.id;

                            if(widgets.filter(itemExists).length > 0){
                                const obj = data.filter(itemExists)[0];
                                const value = obj ? obj[key] : "";

                                return {
                                    id: {
                                        regionID: region.id,
                                        widgetID: widget.id
                                    },
                                    data: [
                                        {column: "widget", value: widget.name || widget.title},
                                        {column: "region", value: region.name},
                                        {column: key, value: value}
                                    ]
                                };
                            }
                            else{
                                return null;
                            }
                        })
                    )
                );

                resolve(ret);
            })
            .catch(reject);
    });
};

export default mapToRegionWidgets;
