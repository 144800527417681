import React, {Component} from "react";
import fetchOptions from "./lib/fetch-options";

class Analytics extends Component{
    constructor(){
        super();

        this.state = {
            data: []
        };

        this.max = 100;

        fetch(`/api/analytics?max=${this.max}`, fetchOptions("GET"))
            .then(res => res.json())
            .then(results => {
                this.setState({
                    data: results
                });
            })
            .catch(console.error);
    }

    render(){
        return (
            <div>
                Showing most recent <strong>{this.max}</strong> results.
                <pre>{JSON.stringify(this.state.data, null, 4)}</pre>
            </div>
        );
    }
}

export default Analytics;
