import React from "react";
import {Switch, Route, Link} from "react-router-dom";

import "font-awesome/css/font-awesome.css";
import FontAwesome from "react-fontawesome";

import path from "path";

const AdminPanel = (props) => {

    // Current route
    const route = props.match.url;

    // Header
    const renderHeader = () => {
        return <Link to={route}>{props.header}</Link>
    };

    // Links
    const renderLinks = () => {

        return props.pages.map(page => (
            <Link
                to={path.join(route, page.route)}
                key={page.route}
                className={path.join(route, page.route) === window.location.pathname ? "active" : ""}
            >
                <div style={{display: "flex"}}>
                    <div style={{flex: "1"}}>{page.name}</div>
                    <div>{page.icon ? <FontAwesome name={page.icon}/> : null}</div>
                </div>
            </Link>
        ));
    };

    // Routes
    const renderRoutes = () => {
        const home = <Route render={props.home}/>;
        const routes = props.pages.map(page =>
            <Route path={path.join(route, page.route)} render={page.render} key={page.route}/>
        );
        return (<Switch>{routes}{home}</Switch>);
    };

    // Render
    return (
        <div id="admin-panel">
            <div id="header">{renderHeader()}</div>
            <div id="wrapper">
                <div id="sidebar">{renderLinks()}</div>
                <div id="body">{renderRoutes()}</div>
            </div>
        </div>
    );
};

export default AdminPanel;