import "./admin-tools/style.scss";
import React from "react";
import moment from "moment";
import Paginator from "./admin-tools/Paginator";

import fetchOptions from "./lib/fetch-options";

class EmailLog extends React.Component{
    constructor(){
        super();

        this.state = {
            log: [],
            logNeedsUpdate: true,
            page: 1,
            pages: 1,
            error: null,
            errorsOnly: false,
            startDate: "",
            endDate: ""
        };
    }

    componentDidMount(){
        this.getLog();
    }

    componentDidUpdate(){
        this.getLog();
    }

    getLog(){
        const s = this.state;

        if(s.logNeedsUpdate){
            this.setState({logNeedsUpdate: false});

            fetch(
                `/api/email-log?page=${s.page}&errorsOnly=${s.errorsOnly ? true : ""}&startDate=${s.startDate}&endDate=${s.endDate}`,
                fetchOptions("GET")
            )
                .then(res => res.json())
                .then(json => this.setState({
                    pages: json.pages,
                    log: json.log
                }))
                .catch(error => this.setState({error}));
        }
    }

    setStartDate(startDate){
        if(!this.state.endDate || moment(startDate).unix() <= moment(this.state.endDate).unix())
            this.setState({
                startDate,
                logNeedsUpdate: true,
                page: 1
            });
        else
            this.setState({
                startDate,
                endDate: "",
                logNeedsUpdate: true,
                page: 1
            });
    }

    setEndDate(endDate){
        if(!this.state.startDate || moment(endDate).unix() >= moment(this.state.startDate).unix())
            this.setState({
                endDate,
                logNeedsUpdate: true,
                page: 1
            });
        else
            this.setState({
                endDate,
                startDate: "",
                logNeedsUpdate: true,
                page: 1
            });
    }

    render(){
        return (
            <div>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <div>
                        <Paginator
                            value={this.state.page}
                            max={this.state.pages}
                            onChange={value => {
                                this.setState({
                                    page: value,
                                    logNeedsUpdate: true
                                });
                            }}/>
                    </div>
                    <div style={{padding: "0 50px"}}>
                        Search between dates:
                        Start: <input
                            type="date"
                            value={this.state.startDate}
                            style={{marginRight: "1em"}}
                            onChange={e => this.setStartDate(e.target.value)}/>
                        End: <input
                            type="date"
                            value={this.state.endDate}
                            onChange={e => this.setEndDate(e.target.value)}/>
                    </div>
                    <div style={{}}>
                        <input
                            type="checkbox"
                            id="errorsOnly"
                            checked={this.state.errorsOnly}
                            onChange={e => {
                                this.setState({
                                    errorsOnly: e.target.checked,
                                    logNeedsUpdate: true,
                                    page: 1
                                });
                            }}/>
                        <label htmlFor="errorsOnly">Only show emails with errors</label>
                    </div>
                </div>

                <br/>

                {this.state.error ? (
                    "There was an error retrieving the email log"
                ) : (
                    <table id="email-log-table">
                        <thead>
                            <tr>
                                <th>Timestamp</th>
                                <th>Subject</th>
                                <th>Exchange ID</th>
                                <th>Accepted</th>
                                <th>Rejected</th>
                                <th>Error</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.log
                            .map(item => {
                                const wrapWithDiv = x => <div key={x}>{x}</div>;

                                const accepted = item.info && item.info.accepted ? item.info.accepted :
                                    (item.error && item.error.accepted ? item.error.accepted : []);
                                const rejected = item.info && item.info.rejected ? item.info.rejected :
                                    (item.error && item.error.rejected ? item.error.rejected : []);

                                const date = new Date(item.datetime.toString());
                                const offset = date.getTimezoneOffset() * 60000;

                                return (
                                    <tr key={item.id}>
                                        <td>{new Date(date.getTime() + offset).toLocaleString("en-US")}</td>
                                        <td>{item.subject}</td>
                                        <td>{item.info ? item.info.messageId : ""}</td>
                                        <td>{accepted.map(wrapWithDiv)}</td>
                                        <td>{rejected.map(wrapWithDiv)}</td>
                                        <td title={item.error ? item.error.response : ""}>{item.error ? item.error.code : ""}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        );
    }
}

export default EmailLog;
