import fetchOptions from './fetch-options';
import widgetData from '../../Main/widget-data';

const widget_order = {
    columns: [
        {
            name: 'region',
            display: 'Region',
            type: 'readonly'
        },
        {
            name: 'widget',
            display: 'Widget',
            type: 'readonly'
        },
        {
            name: 'position',
            display: 'Position',
            type: 'widget-order',
            options: ({widgets, widgetOrder}) => {
                const occupiedWidgets = Object.values(widgets).reduce((a, c) => {
                    if(!a[c.region_id]) a[c.region_id] = [];
                    return a;
                }, {});
                widgets = widgets
                    .sort((a, b) => (a.widget_id < b.widget_id ? -1 : 1))
                    .reduce((a, c) => {
                        if(a[c.region_id] && a[c.region_id].length) a[c.region_id].push(c);
                        else a[c.region_id] = [c];
                        return a;
                    }, {});
                const occupiedPositions = {};
                Object.entries(widgets).forEach(([region, values]) => {
                    occupiedPositions[region] = {};
                    values.forEach((w) => {
                        const foundWidget = widgetOrder.find((wo) =>
                            wo.widget_id === w.widget_id
                            && wo.region_id === w.region_id
                            && wo.position);
                        if(foundWidget) {
                            occupiedPositions[region][foundWidget.position] = true;
                        }
                    });
                    for(let i = 1; i <= values.length; i++) {
                        if(!occupiedPositions[region][i]) {
                            occupiedWidgets[region].push({value: i, text: i});
                        }
                    }
                });
                return occupiedWidgets;
            }
        }
    ],
    read: ({position, regions, regionWidgets}) => {
        const ret = [];

        regionWidgets.forEach((regionWidget) => {
            const widget = widgetData.filter((w) => w.id === regionWidget.widget_id)[0];
            const region = regions.filter((r) => r.id === regionWidget.region_id)[0];
            const filteredPosition = position.filter((p) =>
                p.region_id === regionWidget.region_id
                && p.widget_id === regionWidget.widget_id);

            if(!widget || !region) return;

            ret.push({
                id: {
                    regionID: regionWidget.region_id,
                    widgetID: regionWidget.widget_id
                },
                data: [
                    {column: 'region', value: region.name},
                    {column: 'widget', value: widget.name || widget.title},
                    {
                        column: 'position',
                        value: filteredPosition[0] ? filteredPosition[0].position : ''
                    }
                ]
            });
        });

        return ret.sort((a, b) => (a.id.widgetID < b.id.widgetID ? -1 : 1));
    },
    update: (id, data) => new Promise((resolve, reject) => {
        fetch('/api/widget-order', fetchOptions('POST', {
            id: id.id,
            region_id: id.regionID,
            widget_id: id.widgetID,
            position: data.value
        })).then(resolve).catch(reject);
    }),
    delete: (id) => new Promise((resolve, reject) => {
        fetch('/api/widget-order', fetchOptions('DELETE', {
            region_id: id.regionID,
            widget_id: id.widgetID
        }))
            .then(resolve)
            .catch(reject);
    })
};

const regions = {
    columns: [
        {
            name: "name",
            display: "Region",
            type: "text"
        },
        {
            name: "title",
            display: "Name",
            type: "text"
        },
        {
            name: "url",
            display: "URL",
            type: "text"
        },
        {
            name: "helpText",
            display: "Help Text",
            type: "text"
        },
        {
            name: "header",
            display: "Header",
            type: "image"
        },
        {
            name: "footer",
            display: "Footer",
            type: "image"
        }
    ],
    read: () => {
        return new Promise((resolve, reject) => {
            fetch("/api/regions", fetchOptions("GET"))
                .then(res => res.json())
                .then(json => {
                    resolve(
                        json.map(region => ({
                            id: region.id,
                            data: [
                                {column: "name", value: region.name},
                                {column: "title", value: region.title},
                                {column: "url", value: region.url},
                                {column: "helpText", value: region.helpText},
                                {column: "header", value: region.header},
                                {column: "footer", value: region.footer},
                            ]
                        }))
                    );
                })
                .catch(reject);
        });
    },
    create: data => {
        return new Promise((resolve, reject) => {
            fetch("/api/regions", fetchOptions("POST", {
                name: data.filter(x => x.column === "name")[0].value,
                title: data.filter(x => x.column === "title")[0].value,
                url: data.filter(x => x.column === "url")[0].value,
                helpText: data.filter(x => x.column === "helpText")[0].value,
                header: data.filter(x => x.column === "header")[0].value,
                footer: data.filter(x => x.column === "footer")[0].value
            }))
                .then(res => (res.status === 200 ? resolve : reject)())
                .catch(reject);
        });
    },
    update: (id, data) => {
        return new Promise((resolve, reject) => {
            fetch("/api/regions", fetchOptions("PATCH", {
                id,
                [data.column]: data.value
            }))
                .then(resolve)
                .catch(reject);
        });
    },
    delete: id => {
        return new Promise((resolve, reject) => {
            fetch("/api/regions", fetchOptions("DELETE", {id}))
                .then(resolve)
                .catch(reject);
        });
    }
};

const widgets = {
    rows: () => {
        return Promise.resolve(
            widgetData.map(widget => ({
                value: widget.id,
                text: widget.name || widget.title
            }))
        )
    },
    columns: () => {
        return new Promise((resolve, reject) => {
            fetch("/api/regions", fetchOptions("GET"))
                .then(res => res.json())
                .then(json => {
                    resolve(json.map(region => ({
                        value: region.id,
                        text: region.name
                    })));
                })
                .catch(reject);
        })
    },
    read: () => {
        return new Promise((resolve, reject) => {
            fetch("/api/widgets", fetchOptions("GET"))
                .then(res => res.json())
                .then(json => {
                    resolve(json.map(join => ({
                        row: join.widget_id,
                        column: join.region_id
                    })))
                })
                .catch(reject);
        });
    },
    update: (row, column, state) => {
        return new Promise((resolve, reject) => {
            fetch("/api/widgets", fetchOptions(state ? "POST" : "DELETE", {
                widget_id: row,
                region_id: column
            }))
                .then(resolve)
                .catch(reject);
        });
    }
};

export default {
    widget_order,
    regions,
    widgets
};
