import React from "react";
import fetchOptions from "./Admin/lib/fetch-options";

class Test extends React.Component{
    constructor(){
        super();

        this.state = {
            json: null,
            message: "Getting test results..."
        };

        fetch("/api/test", fetchOptions("GET"))
            .then(res => res.json())
            .then(json => this.setState({json}))
            .catch(() => this.setState({message: "There was an error."}));
    }

    render(){
        if(this.state.json)
            return <pre>{JSON.stringify(this.state.json, null, 4)}</pre>;
        else
            return <div>{this.state.message}</div>;
    }
}

export default Test;




