const non_prod_urls = RegExp('^(localhost|dev-|qa-|uat-)|.*(\.dev.*)$|^dev\..*');
const is_runtime_prod = process.env.NODE_ENV === 'production'
    && !non_prod_urls.test(document.location.hostname);

module.exports = {
    is_runtime_prod,
    localVer: 1,
    minPasswordLength: "8",
    GA_ID: is_runtime_prod ? 'G-ET326HB0FJ' : 'G-G4249D66SW',
    GTM_ID: is_runtime_prod ? 'GTM-5DD22CG' : 'GTM-W5KBJF2',
    ccpaDataDomains: {
        'Washington': '2022fa30-e957-4f95-bd84-9edccf620a2b',
        'Central': 'b3449457-cdc9-4fd0-a87e-c53a09a8b258',
        'Florida': '7722b947-ab45-4860-88af-23232bab1447',
        'New York': '3fbaf588-9df2-44a5-b156-ad347cf248cd',
        'NJ/PA': 'ce982d00-a000-4f88-8a47-4b780f19a6b7'
    },
    RECAPTCHA: {
        v2: is_runtime_prod ? '6LeoLi4aAAAAAF1XgPF1M1jvtidUKpP5XdTmqdkN' : '6LdUQiIaAAAAAOZe_1f_92LXYv47DilsxTK4Quak',
        v3: is_runtime_prod ? '6LeMPC4aAAAAAGFokWXcH1vadW6EiGl35MXHgNX8' : '6Le2fBkaAAAAAIKzOoSTWMvCaIyuKbroSjtOsh2q',
    }
};
