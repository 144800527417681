import React from "react";

import {CrudTable} from "./admin-tools";
import fetchOptions from "./lib/fetch-options";
import mapToRegionWidgets from "./lib/mapToRegionWidgets";

const Email = () => (
    <CrudTable
        columns={[
            {
                name: "region",
                display: "Region",
                type: "readonly"
            },
            {
                name: "widget",
                display: "Widget",
                type: "readonly"
            },
            {
                name: "email",
                display: "Email",
                type: "text"
            }
        ]}
        read={() => {
            return new Promise((resolve, reject) => {
                mapToRegionWidgets("/api/email", "email", (widget) => widget.needsConfiguredEmail)
                    .then(resolve)
                    .catch(reject);
            });
        }}
        update={(id, data) => {
            return new Promise((resolve, reject) => {
                fetch("/api/email", fetchOptions("POST", {
                    region_id: id.regionID,
                    widget_id: id.widgetID,
                    email: data.value
                })).then(resolve).catch(reject);
            });
        }}
    />
);

export default Email;
