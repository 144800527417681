import "./style.scss";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import React from "react";
import {Link, Redirect} from "react-router-dom";

import DatePicker from 'react-datepicker';
import querystring from "querystring";

import { RECAPTCHA } from '../../config';

const honeypot = {
    name: Math.random().toString(36).substring(2),
    type: "text",
    honeypot: true
};

const recaptchaId = 'rv2-container';

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: {},
            options: {},
            errors: [],
            waiting: false,
            redirect: false,
            recaptcha: {
                token: '',
                version: 3,
                retried: false,
                rendered: false,
            },
        };

        this.dom = {};

        // Set default state for 'inputs' and 'options'
        if (props.data.inputs) {                                        // Are there inputs?
            props.data.inputs.concat(honeypot).forEach(input => {           // Loop through inputs
                if (input.type === "select") {
                    const setOptions = options => {
                        if(options[0]){
                            this.setState(prevState => {            // Set option state to resolved data
                                let newState = prevState;

                                newState.options[input.name] = options;
                                newState.inputs[input.name] = options[0].value;

                                if(input.selected){
                                    const selected = input.selected(window);
                                    if(selected)
                                        newState.inputs[input.name] = selected;
                                }

                                return newState;
                            });
                        }
                    };
                    if (typeof(input.options) === "function") {    // Select options are an ASYNC function
                        this.state.options[input.name] = [];            // Set option state to an empty array
                        input.options().then(setOptions);
                    } else if (typeof(input.options.then) === "function") {    // Select options are ASYNC
                        this.state.options[input.name] = [];            // Set option state to an empty array
                        input.options.then(setOptions);
                    } else {                                            // Select options are SYNC
                        this.state.options[input.name] = input.options; // Set option state
                    }
                }
                if(input.type === "date")                               // Create a property on the 'inputs' object
                    this.state.inputs[input.name] = new Date();
                else if(input.type === "radio")
                    this.state.inputs[input.name] = input.options[0];
                else
                    this.state.inputs[input.name] = "";
            });
        }

        // Load querystring values
        const queryString = querystring.parse(window.location.search.substring(1));
        Object.keys(queryString).forEach(parameter => {
            if(this.state.inputs[parameter] !== undefined){
                this.state.inputs[parameter] = queryString[parameter];
            }
        });
    }

    execute = (action, callback) => {
        window.grecaptcha.ready(() =>
            window.grecaptcha.execute(RECAPTCHA.v3, { action })
                .then(token => {
                    this.setState({recaptcha: { ...this.state.recaptcha, token }}, callback)
                })
                .catch(console.error)
        );
    }
      
    renderV2 = () => {
        window.grecaptcha.ready(() => {
            this.recaptchaRef = window.grecaptcha.render(recaptchaId, {
                sitekey: RECAPTCHA.v2,
                callback: this.setV2Token,
                'expired-callback': () => {
                    window.grecaptcha.reset(this.recaptchaRef);
                },
                'error-callback': () => {
                    console.error('grecaptcha error')
                },
            });
            this.setState({recaptcha: { ...this.state.recaptcha, rendered: true }})
        });
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.data.inputs && this.dom[this.props.data.inputs[0].name])
            this.dom[this.props.data.inputs[0].name].focus();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setV2Token = token => {
        this.setState({recaptcha: { ...this.state.recaptcha, token, version: 2, retried: true }})
    }

    render() {
        const data = this.props.data;
        const onSubmit = (e) => {
            e.preventDefault();
            if(this.state.inputs[honeypot.name]){
                this.setState({errors: ["There was an error."]})
            }
            else{
                const submission = () => {
                    this.setState({waiting: true});
                    this.props.onSubmit({...this.state.inputs, captchaVer: this.state.recaptcha.version, captcha: this.state.recaptcha.token})
                        .then(() => {
                            if(this.props.data.successMsg)
                                alert(this.props.data.successMsg);
                            if(this._isMounted){
                                this.setState({
                                    waiting: false,
                                    redirect: !!this.props.data.successRoute
                                });
                            }
                        })
                        .catch(errors => {
                            const reason = typeof errors === "object" ? errors.reason : '';
                            errors = typeof errors === "object" ? errors.errors : errors;
                            if(this._isMounted){
                                this.setState({
                                    waiting: false,
                                    errors: errors ? (typeof errors === "string" ? [errors] : errors) : []
                                });
                            }
                            if(data.recaptcha) {
                                if(!this.state.recaptcha.rendered && reason === "recaptcha-v3-fail") {
                                    this.renderV2()
                                } else if(this.state.recaptcha.rendered) {
                                    window.grecaptcha.reset(this.recaptchaRef)
                                }
                            }
                        })
                        .finally(() => {
                        });
                }
                if(data.recaptcha && !this.state.recaptcha.retried) {
                    this.execute('homepage', submission)
                } else {
                    submission()
                }
            }
        };
        const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

            this.setState(prevState => {
                let newState = prevState;
                newState.inputs[name] = value;
                return newState;
            });
        };
        const isInternalLink = (link) => {
            return link.indexOf("://") === -1;
        };
        const renderTitle = () => {
            const titleText = data.title.text || this.props.titleText;
            return (
                <div style={data.title.centered ? {textAlign: "center"} : {}}>{
                    Array.isArray(titleText) ? (
                        titleText.map(text => <div key={text}>{text}</div>)
                    ) : (
                        titleText
                    )
                }</div>
            );
        };
        const renderErrors = () => {
            return (
                <ul>
                    {this.state.errors.map(error => (
                        <li key={error} dangerouslySetInnerHTML={{__html: error}}></li>
                    ))}
                </ul>
            );
        };
        const renderInputs = () => {
            const inputs = data.inputs.concat(honeypot).map(input => {
                let content;
                const props = {
                    name: input.name,
                    onChange: handleChange,
                    value: this.state.inputs[input.name] ? this.state.inputs[input.name] : "",
                    required: input.required,
                    ref: x => this.dom[input.name] = x
                };
                switch (input.type) {
                    case "text":
                    case "email":
                    case "password":
                        content = <input {...props} type={input.type} placeholder={input.placeholder}/>;
                        break;

                    case "textarea":
                        content = <textarea {...props} placeholder={input.placeholder}/>;
                        break;

                    case "select":
                        const placeholder = (
                            <option value="" style={{color: "grey"}} disabled>{input.placeholder}</option>
                        );
                        const options = this.state.options[input.name].map(option => (
                            <option
                                value={option.value}
                                style={{color: "#101010"}}
                                key={option.value}
                            >
                                {option.text}
                            </option>
                        ));
                        content = (
                            <select
                                {...props}
                            >
                                {placeholder}
                                {options}
                            </select>
                        );
                        break;

                    case "radio":
                        const buttons = input.options.map(option =>
                            <div key={option}>
                                <input
                                    type="radio"
                                    name={input.name}
                                    id={"radio-" + option}
                                    value={option}
                                    onChange={handleChange}
                                    checked={this.state.inputs[input.name] === option}/>
                                <label htmlFor={"radio-" + option}>{option}</label>
                            </div>
                        );
                        content = (
                            <div className="add-border">
                                {input.placeholder}
                                {buttons}
                            </div>
                        );
                        break;

                    case "date":
                        content = (
                            <div>
                                {input.placeholder}
                                <DatePicker
                                    selected={this.state.inputs[input.name]}
                                    onChange={date => {
                                        this.setState(prevState => {
                                            let newState = prevState;
                                            newState.inputs[input.name] = date;
                                            return newState;
                                        });
                                    }}
                                    showTimeSelect
                                    dateFormat="Pp"/>
                            </div>
                        );
                        break;

                    case "checkbox":
                        content = (
                            <div>
                                <input
                                    type="checkbox"
                                    id={`checkbox-${input.name}`}
                                    checked={this.state.inputs[input.name]}
                                    name={input.name}
                                    onChange={handleChange}/>
                                <label htmlFor={`checkbox-${input.name}`}>{input.placeholder}</label>
                            </div>
                        );
                        break;
                        // no default
                }
                return (
                    <tr key={input.name} style={input.honeypot ? {display: "none"} : {}}>
                        <td className="icon">
                            <img
                                src={input.icon || (input.type === "date" ? require("../../img/icons/forms/calendar.png") : require("../../img/icons/forms/dot.png"))}
                                alt="icon"
                            />
                        </td>
                        <td className="content">{content}</td>
                    </tr>
                );
            });
            return (
                <table>
                    <tbody>{inputs}</tbody>
                </table>
            );
        };
        const renderButtons = () => {
            return data.buttons.map(button => {
                switch (button.type) {
                    case "submit":
                        if(this.state.waiting)
                            if(button.captcha) {
                                return (
                                    <div key={button.text} className="recaptcha-wrapper">
                                        <small>
                                            This site is protected by reCAPTCHA and the Google
                                            <a className="underline" href="https://policies.google.com/privacy">Privacy Policy</a> and
                                            <a className="underline" href="https://policies.google.com/terms">Terms of Service</a> apply.
                                        </small>
                                        <div className="recaptcha-v2" id={recaptchaId}></div>
                                        <button type="submit" disabled>Waiting...</button>
                                    </div>
                                );
                            } else
                                return <button type="submit" key={button.text}>{button.text}</button>
                        else {
                            if(button.captcha) {
                                return (
                                    <div key={button.text} className="recaptcha-wrapper">
                                        <small>
                                            This site is protected by reCAPTCHA and the Google
                                            <a className="underline" href="https://policies.google.com/privacy">Privacy Policy</a> and
                                            <a className="underline" href="https://policies.google.com/terms">Terms of Service</a> apply.
                                        </small>
                                        <div className="recaptcha-v2" id={recaptchaId}></div>
                                        <button type="submit">{button.text}</button>
                                    </div>
                                );
                            } else
                                return <button type="submit" key={button.text}>{button.text}</button>
			            }

                    case "link":
                        if(!isInternalLink(button.url))
                            return <a href={button.url} key={button.text} target="_blank" rel="noopener noreferrer">{button.text}</a>;
                        else{
                            const url = button.url + (button.share ? `?${button.share.map(x => `${x}=${this.state.inputs[x]}`).join("&")}` : "");
                            if(button.forceRefresh)
                                return <a href={url} key={button.text}>{button.text}</a>;
                            else
                                return <Link to={url} key={button.text}>{button.text}</Link>;
                        }
                        // no default
                }
                return null;
            });
        };
        const renderLinks = () => {
            return data.links.map(link => (
                isInternalLink(link.url) ? (
                    <Link
                        to={link.url + (link.share ? `?${link.share.map(x => `${x}=${this.state.inputs[x]}`).join("&")}` : "")}
                        key={link.text}
                    >
                        {link.text}
                    </Link>
                ) : (
                    <a href={link.url} target="_blank" rel="noopener noreferrer" key={link.text}>{link.text}</a>
                )
            ));
        };
        const maybeShow = (className, callback, condition) => {
            if (condition)
                return <div className={className}>{callback()}</div>;
            else
                return null;
        };
        if (this.state.redirect)
            return <Redirect to={data.successRoute}/>;
        else {
            return (
                <form id="form" className="invert" onSubmit={onSubmit}>
                    {maybeShow("title", renderTitle, data.title)}
                    {maybeShow("errors", renderErrors, this.state.errors.length > 0)}
                    {maybeShow("inputs", renderInputs, data.inputs)}
                    {maybeShow("buttons", renderButtons, data.buttons)}
                    {maybeShow("links", renderLinks, data.links)}
                    {this.props.after}
                </form>
            );
        }
    }
}

export default Form;
