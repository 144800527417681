import "./style.scss";

import React from "react";

import fetchOptions from "../../Admin/lib/fetch-options";

import Form from "../Form";

import callMeWithMessage from "./forms/call-me-with-message";
import orderStatusForm from "./forms/order-status";
import premiumPickUp from "./forms/premium-pick-up";
import openTitle from "./forms/open-title";
import orderBook from "./forms/order-book";
import legalTrio from "./forms/legal-trio";
import myCloser from "./forms/my-closer";
import agentAuthorizationLetter from "./forms/agent-authorization-letter";

import moment from "moment";


const formatDateTime = datetime => moment(datetime).format("MMMM Do YYYY, h:mm a");

const formOnSubmit = (endpoint, fieldsCb) => {
    return fields => new Promise((resolve, reject) => {
        fetch(endpoint, fetchOptions("POST", fieldsCb ? fieldsCb(fields) : fields))
            .then(res => res.status === 503 ? reject("You're sending emails too quickly!") : resolve())
            .catch(reject);
    });
};

const pickup = (title) => (
    () => (
        <Form
            data={premiumPickUp(title)}
            onSubmit={formOnSubmit("/api/widget-functions/premium-pick-up", fields => ({
                datetime: formatDateTime(fields.datetime),
                message: fields.message
            }))}/>
    )
);

export default [
    {
        id: 1,
        name: "Call Me (with message)",
        title: "Call Me",
        icon: require("../../img/icons/widgets/phone.svg"),
        type: "render",
        route: "/call-me",
        callback: () => <Form data={callMeWithMessage} onSubmit={formOnSubmit("/api/widget-functions/call-me")}/>
    },
    {
        id: 16,
        name: "Call Me (without message)",
        title: "Call Me",
        icon: require("../../img/icons/widgets/phone.svg"),
        type: "action",
        route: "/call-me",
        needsConfiguredEmail: true,
        callback: () => {
            fetch("/api/widget-functions/call-me", fetchOptions("POST"))
                .catch(console.error);
            alert("Thank you, we'll call you shortly!");
        }
    },
    {
        id: 2,
        name: "Contact Info",
        title: "$NAME",
        icon: require("../../img/icons/widgets/person.svg"),
        type: "render",
        route: "/contact-info",
        callback: ({email, phone, website, address, instagram, linkedin, facebook}) => {
            const items = [
                {
                    text: email,
                    link: "mailto:" + email,
                    icon: require("../../img/icons/forms/email.svg"),
                    displayCondition: email
                },
                {
                    text: phone + " (Call)",
                    link: "tel:" + phone,
                    icon: require("../../img/icons/forms/phone.svg"),
                    displayCondition: phone
                },
                {
                    text: phone + " (Text)",
                    link: "sms:" + phone,
                    icon: require("../../img/icons/forms/text-message.svg"),
                    displayCondition: phone
                },
                {
                    text: website,
                    link: website,
                    icon: require("../../img/icons/forms/website.svg"),
                    displayCondition: website
                },
                {
                    text: "Follow on Instagram",
                    link: instagram,
                    icon: require("../../img/icons/forms/instagram.svg"),
                    displayCondition: instagram
                },
                {
                    text: "Connect on LinkedIn",
                    link: linkedin,
                    icon: require("../../img/icons/forms/linkedin.svg"),
                    displayCondition: linkedin
                },
                {
                    text: "Friends on Facebook",
                    link: facebook,
                    icon: require("../../img/icons/forms/facebook.svg"),
                    displayCondition: facebook
                },
                {
                    text: address.replace(/\n/g,'<br/>'),
                    icon: require("../../img/icons/forms/address.svg"),
                    displayCondition: address
                }
            ];

            return (
                <div id="contact-info">
                    {items.map((item, i) => (
                        item.displayCondition ? (
                            <a href={item.link} target="_blank" rel="noopener noreferrer" className="item" key={i}>
                                <div className="image">
                                    <img src={item.icon} alt="contact info"/>
                                </div>
                                <div className="text" dangerouslySetInnerHTML={{__html: item.text}}/>
                            </a>
                        ) : null
                    ))}
                </div>
            );
        }
    },
    {
        id: 23,
        title: "AMS2",
        icon: require("../../img/icons/widgets/pokeball.svg"),
        type: "link"
    },
    {
        id: 24,
        title: "WFG New York Bulletins & Forms",
        icon: require("../../img/icons/widgets/document.svg"),
        type: "link"
    },
    {
        id: 25,
        title: "WFG Events Calendar",
        icon: require("../../img/icons/widgets/megaphone.svg"),
        type: "link"
    },
    {
        id: 7,
        title: "Calculator",
        icon: require("../../img/icons/widgets/calculator.svg"),
        type: "link"
    },
    {
        id: 20,
        title: "WFG Blocks",
        icon: require("../../img/icons/widgets/open-title.svg"),
        type: "link"
    },
    {
        id: 19,
        title: "WFG Marketing Center",
        icon: require("../../img/icons/widgets/open-title.svg"),
        type: "link"
    },
    {
        id: 21,
        title: "WFG Preferred Vendor Program",
        icon: require("../../img/icons/widgets/open-title.svg"),
        type: "link"
    },
    {
        id: 3,
        name: "Region Contacts",
        title: "$REGION Contacts",
        icon: require("../../img/icons/widgets/contacts.svg"),
        type: "link"
    },
    {
        id: 4,
        title: "Order Title Search",
        icon: require("../../img/icons/widgets/pokeball.svg"),
        type: "link",
        variations: ["ResWare", "RamQuest"],
        variables: [{symbol: "{ClientID}", variations: ["ResWare"]}],
        linkCB: (links, {user}) => {
            return new Promise((resolve, reject) => {
                fetch("/api/resware-domains", fetchOptions("GET"))
                    .then(res => res.json())
                    .then(domains => {
                        const userDomain = user.email.slice(user.email.indexOf("@")+1);
                        if(!domains[userDomain])
                            resolve(links["RamQuest"]);
                        else
                            resolve(links["ResWare"].replace("{ClientID}", domains[userDomain]));
                    })
                    .catch(reject);
            });
        }
    },
    {
        id: 5,
        title: "Order Update / Status",
        icon: require("../../img/icons/widgets/megaphone.svg"),
        type: "render",
        route: "/order-status",
        needsConfiguredEmail: true,
        callback: () => (
            <Form
                data={orderStatusForm}
                onSubmit={formOnSubmit("/api/widget-functions/order-status", fields => ({
                    fileNumber: fields.fileNumber,
                    propertyAddress: fields.propertyAddress,
                    buyerSellerName: fields.buyerSellerName,
                    updateType: fields.updateType,
                    updateTime: formatDateTime(fields.updateTime),
                    message: fields.message
                }))}/>
        )
    },
    {
        id: 6,
        title: "CPL / Jacket Generator",
        icon: require("../../img/icons/widgets/document.svg"),
        type: "link"
    },
    {
        id: 10,
        title: "WFG Tools",
        icon: require("../../img/icons/widgets/wfgtools.svg"),
        type: "link"
    },
    {
        id: 12,
        title: "Earnest $",
        icon: require("../../img/icons/widgets/key.svg"),
        type: "render",
        route: "/earnest-pick-up",
        needsConfiguredEmail: true,
        callback: pickup("Earnest Money Pick Up")
    },
    {
        id: 13,
        title: "Open Title",
        icon: require("../../img/icons/widgets/open-title.svg"),
        type: "render",
        route: "/open-title",
        needsConfiguredEmail: true,
        callback: () => <Form data={openTitle} onSubmit={formOnSubmit("/api/widget-functions/open-title")}/>
    },
    {
        id: 42,
        title: "Order Homebook",
        icon: require("../../img/icons/widgets/book.svg"),
        type: "render",
        route: "/order-book",
        needsConfiguredEmail: true,
        callback: () => <Form data={orderBook} onSubmit={formOnSubmit("/api/widget-functions/order-book")}/>
    },
    {
        id: 14,
        title: "Legal / Trio",
        icon: require("../../img/icons/widgets/legal-trio.svg"),
        type: "render",
        route: "/legal-trio",
        needsConfiguredEmail: true,
        callback: () => <Form data={legalTrio} onSubmit={formOnSubmit("/api/widget-functions/legal-trio")}/>
    },
    {
        id: 15,
        title: "My Closer",
        icon: require("../../img/icons/widgets/my-closer.svg"),
        type: "render",
        route: "/my-closer",
        needsConfiguredEmail: true,
        callback: () => <Form data={myCloser} onSubmit={formOnSubmit("/api/widget-functions/my-closer")}/>
    },
    {
        id: 8,
        title: "Premium Pick Up",
        icon: require("../../img/icons/widgets/key.svg"),
        type: "render",
        route: "/premium-pick-up",
        callback: pickup("Premium Pick Up")
    },
    {
        id: 9,
        title: "WFGAgent.com",
        icon: require("../../img/icons/widgets/location.svg"),
        type: "link"
    },
    {
        id: 32,
        title: "WFGTitle.com",
        icon: require("../../img/icons/widgets/location.svg"),
        type: "link"
    },
    {
        id: 11,
        title: "Branches",
        icon: require("../../img/icons/widgets/branches.svg"),
        type: "link"
    },
    {
        id: 22,
        title: "New York Agency Contacts",
        icon: require("../../img/icons/widgets/contacts.svg"),
        type: "link"
    },
    {
        id: 41,
        title: "Central Division Contacts",
        icon: require("../../img/icons/widgets/branches.svg"),
        type: "link"
    },
    {
        id: 17,
        title: "Over Limit Authority Request | Policy Jackets",
        icon: require("../../img/icons/widgets/open-title.svg"),
        type: "link"
    },
    {
        id: 18,
        title: "Agent Authorization Letter",
        icon: require("../../img/icons/widgets/open-title.svg"),
        type: "render",
        route: "/agent-authorization-letter",
        needsConfiguredEmail: true,
        callback: () => <Form data={agentAuthorizationLetter} onSubmit={formOnSubmit("/api/widget-functions/agent-authorization-letter")}/>
    },
    {
        id: 30,
        title: "WFG Ignition Learning Center",
        icon: require("../../img/icons/widgets/learning-center.png"),
        type: "link"
    },
    {
        id: 31,
        title: "Agent Training",
        icon: require("../../img/icons/widgets/agent-training.png"),
        type: "link"
    },
    {
        id: 40,
        title: "WFG Underwriting",
        icon: require("../../img/icons/widgets/location.svg"),
        type: "link"
    }
]
