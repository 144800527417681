import React from "react";
import {JoinTable} from "./admin-tools";
import options from "./lib/table-props";

const Widgets = () => (
    <JoinTable
        {...options.widgets}
    />
);

export default Widgets;
