import React from "react";
import {Redirect} from "react-router-dom"

import {AdminPanel, Uploads} from "./admin-tools";

import Reps from "./Reps";
import Users from "./Users";
import Regions from "./Regions";
import Widgets from "./Widgets";
import WidgetOrder from "./WidgetOrder";
import Links from "./Links";
import Email from "./Email";
import EmailLog from "./EmailLog";
import Analytics from "./Analytics";
import Closers from "./Closers";

class Admin extends React.Component{
    constructor(){
        super();

        this.loggedInState = {
            loading: 0,
            success: 1,
            failure: 2
        };

        this.state = {
            authentication: this.loggedInState.loading
        };

        fetch("/api/users", {
            method: "GET",
            credentials: "same-origin"
        })
        .then((response) => {
            if(response.status < 400)
                this.setState({authentication: this.loggedInState.success});
            else
                this.setState({authentication: this.loggedInState.failure});
        })
        .catch(() => {
            this.setState({authentication: this.loggedInState.failure});
        });
    }

    render(){
        switch(this.state.authentication){
            case this.loggedInState.loading:
                return (<div>Loading...</div>);

            case this.loggedInState.success:
                return (
                    <AdminPanel
                        {...this.props}
                        header="WFG App Admin"
                        home={() => <div>Please select an item from the menu.</div>}
                        pages={[
                            {
                                name: "Reps",
                                icon: "id-badge",
                                route: "/reps",
                                render: Reps
                            },
                            {
                                name: "Users",
                                icon: "users",
                                route: "/users",
                                render: Users
                            },
                            {
                                name: "Regions",
                                icon: "map-marker",
                                route: "/regions",
                                render: Regions
                            },
                            {
                                name: "Widgets",
                                icon: "minus-square",
                                route: "/widgets",
                                render: Widgets
                            },
                            {
                                name: "Widget Order",
                                icon: "sort-numeric-asc",
                                route: "/widget-order",
                                render: WidgetOrder
                            },
                            {
                                name: "Closers",
                                icon: "user-circle",
                                route: "/closers",
                                render: Closers
                            },
                            {
                                name: "Links",
                                icon: "link",
                                route: "/links",
                                render: Links
                            },
                            {
                                name: "Email",
                                icon: "envelope",
                                route: "/email",
                                render: Email
                            },
                            {
                                name: "Uploads",
                                icon: "upload",
                                route: "/uploads",
                                render: () => <Uploads endpoint="/api/uploads" />
                            },
                            {
                                name: "Email Log",
                                icon: "bars",
                                route: "/email-log",
                                render: () => <EmailLog/>
                            },
                            {
                                name: "Analytics",
                                icon: "database",
                                route: "/analytics",
                                render: () => <Analytics/>
                            },
                            {
                                name: "Logout",
                                icon: "sign-out",
                                route: "/logout",
                                render: () => <a href="/?logout=true" style={{fontStyle: "italic"}}>Click here to logout.</a>
                            }
                        ]}
                    />
                );

            default:
                return (<Redirect to="/"/>);
        }
    }
}

export default Admin;