import React from "react";

import {CrudTable} from "./admin-tools";
import fetchOptions from "./lib/fetch-options";
import widgetData from "../Main/widget-data";

const Links = () => (
    <CrudTable
        columns={[
            {
                name: "region",
                display: "Region",
                type: "readonly"
            },
            {
                name: "widget",
                display: "Widget",
                type: "readonly"
            },
            {
                name: "variation",
                display: "Variation",
                type: "readonly"
            },
            {
                name: "variables",
                display: "Variables",
                type: "readonly"
            },
            {
                name: "link",
                display: "Link",
                type: "text"
            }
        ]}
        read={() => {
            return new Promise((resolve, reject) => {
                Promise.all([
                    "/api/links",
                    "/api/regions",
                    "/api/widgets"
                ].map(url => fetch(url, fetchOptions("GET")).then(res => res.json())))
                    .then(result => {
                        const api = {
                            links: result[0],
                            regions: result[1],
                            regionWidgets: result[2]
                        };

                        let ret = [];

                        api.regionWidgets.forEach(regionWidget => {
                            const widget = widgetData.filter(widget => widget.id === regionWidget.widget_id)[0];
                            const region = api.regions.filter(region => region.id === regionWidget.region_id)[0];
                            const links = api.links.filter(link => link.region_id === regionWidget.region_id && link.widget_id === regionWidget.widget_id);

                            if(!widget || widget.type !== "link" || !region) return;

                            if(!widget.variations)
                                widget.variations = [""];

                            widget.variations.forEach(variation => {
                                const filteredLinks = links.filter(link => link.variation === variation);

                                const variables = widget.variables ?
                                    widget.variables.filter(item => !item.variations || item.variations.indexOf(variation) !== -1) :
                                    [];

                                const variablesConcat = variables.length > 0 ?
                                    variables.map(item => item.symbol).join(", ") :
                                    "[None]";

                                ret.push({
                                    id: {
                                        regionID: regionWidget.region_id,
                                        widgetID: regionWidget.widget_id,
                                        variation: variation
                                    },
                                    data: [
                                        {column: "region", value: region.name},
                                        {column: "widget", value: widget.name || widget.title},
                                        {column: "variation", value: variation || "[None]"},
                                        {column: "variables", value: variablesConcat},
                                        {
                                            column: "link",
                                            value: filteredLinks[0] ? filteredLinks[0].link : ""
                                        }
                                    ]
                                })
                            });
                        });

                        resolve(ret.sort((a, b) => {
                            if(a.id.widgetID > b.id.widgetID)
                                return 1;
                            else if(a.id.widgetID < b.id.widgetID)
                                return -1;
                            else{
                                if(a.id.regionID > b.id.regionID)
                                    return 1;
                                else if(a.id.regionID < b.id.regionID)
                                    return -1;
                                else{
                                    if(a.id.variation > b.id.variation)
                                        return 1;
                                    else if(a.id.variation < b.id.variation)
                                        return -1;
                                    else{
                                        return 0;
                                    }
                                }
                            }
                        }));
                    })
                    .catch(reject);
            });
        }}
        update={(id, data) => {
            return new Promise((resolve, reject) => {
                fetch("/api/links", fetchOptions("POST", {
                    region_id: id.regionID,
                    widget_id: id.widgetID,
                    variation: id.variation,
                    link: data.value
                })).then(resolve).catch(reject);
            });
        }}
    />
);

export default Links;
