export default {

    /* -- ON SUCCESS -- */
    successMsg: "Success! You will receive an update shortly.",
    successRoute: "/",

    /* -- TITLE -- */
    title:{
        text: "Enter your file number below to receive an update on your order.",
        centered: false
    },

    /* -- INPUTS -- */
    inputs: [
        {
            name: "fileNumber",
            placeholder: "WFG File Number",
            type: "text",
            required: true
        },
        {
            name: "propertyAddress",
            placeholder: "Property Address",
            type: "text",
            required: true
        },
        {
            name: "buyerSellerName",
            placeholder: "Buyer or Seller Last Name",
            type: "text",
            required: true
        },
        {
            name: "updateType",
            placeholder: "Request Type",
            type: "radio",
            options: [
                "Pre Closing",
                "Post Closing",
                "Order Status"
            ],
            required: true
        },
        {
            name: "updateTime",
            placeholder: "Requested Due Date",
            type: "date",
            required: true,
            time: true
        },
        {
            name: "message",
            placeholder: "Note",
            type: "textarea"
        }
    ],

    /* -- BUTTONS -- */
    buttons: [
        {
            text: "Request Update",
            type: "submit"
        }
    ]
};
