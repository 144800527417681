import "./style.scss";
import "./region-style.scss";

import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import querystring from "querystring";

import config from "../config";
import fetchOptions from "../Admin/lib/fetch-options";

import Form from "./Form";

import loginForm from "./forms/login";
import adminLoginForm from "./forms/admin-login";
import registerForm from "./forms/register";
import resetPasswordForm from "./forms/reset-password";
import requestResetPasswordForm from "./forms/request-reset-password";
import needsRepForm from "./forms/needs-rep";

import Widgets from "./Widgets";
import widgetData from "./widget-data";
import base64url from "base64url";

const post = (body) => ({
    method: "POST",
    credentials: "same-origin",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
});

const clearLocalStorage = () => {
    const storage = window.localStorage;
    const version = Number(storage.getItem('localVer'));
    if(!version || version < config.localVer) {
        storage.setItem('localVer', `${config.localVer}`);
        storage.removeItem('email');
        storage.removeItem('password');
        storage.removeItem('jwt');
    }
};

class Main extends React.Component {
    constructor(props) {
        super(props);

        this.query = querystring.parse(window.location.search.substring(1));

        this.state = {
            role: "",
            rep: {},
            widgets: [],
            title: "WFG App",
            images: {
                header: "",
                footer: ""
            },
            useAdminLogin: false,
            needsRep: false,
            helpText: "",
        };

        this.getCCPADomain = (region = 'Washington') => {
            return config.ccpaDataDomains[region];
        };

        fetch("/api/regions")
            .then(res => res.json())
            .then(regions => {
                const filtered = regions.filter(r => r.url ? r.url.split("\n").map(x => x.trim()).indexOf(window.location.hostname) !== -1 : false);
                const region = filtered[0] || regions[0];
                if(region){
                    this.setState({
                        regionName: region.name,
                        title: region.title || this.state.title,
                        helpText: region.helpText,
                        images: {
                            header: region.header,
                            footer: region.footer
                        }
                    });
                }
            });

        if(this.query.logout){
            this.state.role = "none";
            this.logout()
                .catch(console.error)
        } else {
            const payload = window.localStorage.getItem('localVer') ? {} : {
                email: window.localStorage.getItem('email'),
                password: window.localStorage.getItem('password'),
            }
            this.login(payload)
                .then(clearLocalStorage)
                .catch(console.error);
        }
    }

    verifyUser() {
        return new Promise((resolve, reject) => {
            fetch("/api/user/verify")
                .then(res => res.json())
                .then(data => {
                    switch(data.role) {
                        case "user":
                            fetch("/api/analytics", fetchOptions("POST", {
                                eventName: "Front Page"
                            })).then().catch();

                            this.setState({
                                role: "user",
                                user: data.user,
                                rep: data.rep,
                                widgets: data.widgets
                            });

                            if(!data.rep.email)
                                this.setState({needsRep: true});

                            break;

                        case "admin":
                        case "national-admin":
                        case "super-admin":
                            this.setState({
                                role: data.role
                            });
                            break;
                        default:
                            this.setState({role: "none"});
                    }
                })
                .catch(reject)
        })
    }

    logout() {
        return new Promise((resolve, reject) => {
            fetch("/api/user/logout")
                .then(res => res.json())
                .then(resolve)
                .catch(reject)
        })
    }

    login({email, password}) {
        return new Promise((resolve, reject) => {
            fetch("/api/user/login", post({email, password}))
                .then(res => res.json())
                .then(data => {
                    if(data.needsPassword){
                        this.setState({useAdminLogin: true});
                        reject();
                    }
                    else if (data.userFound) {
                        if(data.passwordExpired){
                            fetch("/api/user/request-reset-password", post({email}))
                                .then(res => res.json())
                                .then(data => {
                                    if (!data.success)
                                        alert("There was an error sending you an email.");
                                });
                            reject("Your password is expired. Please check your email for a link to reset it.");
                        }
                        switch (data.role) {
                            case "user":
                                fetch("/api/analytics", fetchOptions("POST", {
                                    eventName: "Front Page"
                                })).then().catch();

                                this.setState({
                                    role: "user",
                                    user: data.user,
                                    rep: data.rep,
                                    widgets: data.widgets
                                });

                                if(!data.rep.email)
                                    this.setState({needsRep: true});

                                break;

                            case "admin":
                            case "national-admin":
                            case "super-admin":
                                this.setState({
                                    role: data.role
                                });
                                break;
                            default:
                                this.setState({
                                    role: "none"
                                });
                                reject("You have not yet confirmed your account. Please check your email, or click <a href='/api/user/resend-verification/" + base64url.encode(email) + "'>here</a> to resend");
                                break;
                        }
                        resolve();
                    }
                    else {
                        this.setState({role: "none"});
                        reject("Username or password is incorrect");
                    }
                })
                .catch(e => reject(e));
        });
    }

    register = ({firstName, lastName, email, phone, repID, captchaVer, captcha}) => {
        return new Promise((resolve, reject) => {
            fetch("/api/user/register", post({firstName, lastName, email, phone, repID, captchaVer, captcha}))
                .then(res => res.json())
                .then(data => {
                    if(data.success)
                        resolve();
                    else
                        reject({errors: data.error || "There was an error during registration.", reason: data.reason});
                });
        });
    }

    requestResetPassword({email}) {
        return new Promise((resolve, reject) => {
            fetch("/api/user/request-reset-password", post({email}))
                .then(res => res.json())
                .then(data => {
                    if (data.success)
                        resolve();
                    else
                        reject(data.error || "There was an error resetting your password.");
                });
        });
    }

    resetPassword({password, verifyPassword}) {
        return new Promise((resolve, reject) => {
            if (password !== verifyPassword)
                reject("Passwords do not match.");
            else if (password.length < config.minPasswordLength)
                reject("Password must be at least " + config.minPasswordLength + " characters.");
            else {
                fetch("/api/user/reset-password", fetchOptions("POST", {password, jwt: this.query.jwt}))
                    .then(res => res.json())
                    .then(data => {
                        if (data.success)
                            resolve();
                        else
                            reject("There was an error resetting your password.");
                    })
                    .catch(() => {
                        reject("There was an error resetting your password.");
                    });
            }
        });
    }

    render() {
        const Header = (props) => (
            <div id="header">
                <div id="image">
                    <img
                        src={props.image}
                        alt={"header"}
                        onError={e => e.target.style.display = "none"}
                        className={props.profile ? "profile-img" : ""}/>
                </div>
                <div id="title">{props.title}</div>
                <div id="subtitle">{props.subtitle}</div>
            </div>
        );

        let root = null,
            login = null,
            register = null;

        switch (this.state.role) {
            case "user":
                if(this.state.needsRep){
                    root = (<Redirect to="/login"/>);
                    login = (
                        <Form
                            data={needsRepForm}
                            after={<div>{this.state.helpText}</div>}
                            onSubmit={({rep}) => {
                                return new Promise((resolve, reject) => {
                                    fetch("/api/user/change-rep", fetchOptions("POST", {rep}))
                                        .then(() => {
                                            this.login({})
                                                .then(() => {
                                                    this.setState({needsRep: false});
                                                    resolve();
                                                })
                                                .catch(reject);
                                        })
                                        .catch(reject);
                                });
                            }}
                        />
                    );
                }
                else{
                    root = (
                        <Widgets widgetData={widgetData.filter(x => this.state.widgets.indexOf(x.id) !== -1)}
                                 userData={this.state.user}
                                 repData={this.state.rep}/>
                    );
                    login = (<Redirect to="/"/>);
                }
                register = (<Redirect to="/"/>);
                break;

            case "admin":
            case "national-admin":
            case "super-admin":
                root = (<Redirect to="/admin"/>);
                login = (<Redirect to="/admin"/>);
                register = (<Redirect to="/admin"/>);
                break;
            default:
                root = (<Redirect to="/login"/>);
                login = (
                    <Form
                        data={this.state.useAdminLogin ? adminLoginForm : loginForm}
                        titleText={[
                            `Welcome to ${this.state.title}!`,
                            "Please Log In"
                        ]}
                        onSubmit={this.login.bind(this)}/>
                );
                register = <Form data={registerForm} onSubmit={this.register.bind(this)}/>;
                break;
        }

        return (
            <div id="main" className={this.state.rep.region ? this.state.rep.region.toLowerCase() : ""}>
                <div id="content">

                    {/* Header */}
                    <Switch>
                        <Route path="/login" render={() => (
                            <Header
                                image={this.state.images.header}/>
                        )}/>
                        <Route path="/register" render={() => (
                            <Header
                                image={this.state.images.header}/>
                        )}/>
                        <Route path="/reset-password" render={() => (
                            <Header
                                image={this.state.images.header}/>
                        )}/>
                        <Route render={() => (
                            <Header
                                title={this.state.rep.name}
                                subtitle={this.state.rep.title}
                                image={this.state.rep.image}
                                profile={true}/>
                        )}/>
                    </Switch>

                    {/* Body */}
                    <div id="body">
                        <Switch>
                            <Route path="/login" key="login" render={() => login}/>
                            <Route path="/register" key="register" render={() => register}/>
                            <Route path="/reset-password" key="reset-password" render={() => {
                                if (this.query.jwt) {
                                    return (<Form data={resetPasswordForm} onSubmit={this.resetPassword.bind(this)}/>);
                                }
                                else {
                                    return (<Form data={requestResetPasswordForm}
                                                  onSubmit={this.requestResetPassword.bind(this)}/>);
                                }
                            }}/>
                            <Route key="default" render={() => root}/>
                        </Switch>
                    </div>
                </div>
                <Helmet>
                    {/* OneTrust Cookies Consent Notice start */}

                    {this.state.regionName &&
                    <script type="text/javascript"
                            src={`https://cdn.cookielaw.org/consent/${this.getCCPADomain(this.state.regionName) + (config.is_runtime_prod ? '' : '-test')}/OtAutoBlock.js`}></script>}
                    {this.state.regionName &&
                    <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript"
                            charset="UTF-8"
                            data-domain-script={this.getCCPADomain(this.state.regionName) + (config.is_runtime_prod ? '' : '-test')}></script>}
                    {this.state.regionName && <script type="text/javascript">{`
                        function OptanonWrapper() { }
                    `}</script>}
                    {/* OneTrust Cookies Consent Notice end */}
                    <title>{this.state.title}</title>
                    <link rel="apple-touch-icon" sizes="57x57" href={this.state.rep.image}/>
                    <link rel="apple-touch-icon" sizes="72x72" href={this.state.rep.image}/>
                    <link rel="apple-touch-icon" sizes="114x114" href={this.state.rep.image}/>
                    <link rel="apple-touch-icon" sizes="144x144" href={this.state.rep.image}/>
                    <script src={`https://www.google.com/recaptcha/api.js?render=${config.RECAPTCHA.v3}`} async defer></script>
                </Helmet>
            </div>
        );
    }
}

export default Main;
