import React from "react";

import {CrudTable} from "./admin-tools";
import options from "./lib/table-props";

const Regions = () => (
    <CrudTable
        {...options.regions}
    />
);

export default Regions;
