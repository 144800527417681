import React from "react";
import Dropzone from "react-dropzone";

export default {
    "image": ({id, field, stringID, isCreateRow, updateBoth}) => {
        return (
            <Dropzone
                multiple={false}
                style={{
                    height: "100%",
                    width: "100%",
                }}
                onDrop={files => {
                    const data = new FormData();
                    data.append("file", files[0]);
                    fetch("/api/uploads", {
                        method: "POST",
                        body: data,
                        credentials: "same-origin"
                    })
                        .then(res => res.json())
                        .then(URLs => {
                            updateBoth({id, value: URLs[0], field, stringID, isCreateRow});
                        });
                }}
            >
                {field.value ? (
                    <img src={field.value} alt="DropZone"/>
                ) : (
                    <div className="img-placeholder">Click here to upload</div>
                )}
            </Dropzone>
        );
    },
    "readonly": ({value}) => <div>{value}</div>,
    "readonly-select": ({options, value}) => {
        const item = options.filter(option => option.value === value)[0];
        return <div>{item ? item.text : ""}</div>;
    },
    "select": ({id, options, message, field, stringID, isCreateRow, onChange}) => {
        const selectOptions = options.map(option => (<option key={"key_" + option.value} value={option.value}>{option.text}</option>));
        const defaultOption = <option key="default" value="" disabled>{message}</option>;

        return (
            <select
                value={field.value || ""}
                onChange={e => onChange({id, value: e.target.value, field, stringID, isCreateRow})}
                disabled={field.disabled}
            >
                {defaultOption}
                {selectOptions}
            </select>
        );
    },
    "text": ({id, value, field, stringID, isCreateRow, placeholder, onChange, onBlur}) => {
        return (
            <textarea
                value={field.value || ""}
                onChange={e => onChange({value: e.target.value, field, stringID, isCreateRow})}
                onBlur={() => onBlur({id, value, field, stringID, isCreateRow})}
                disabled={field.disabled}
                placeholder={placeholder}/>
        );
    },
    "widget-order": ({id, value, disabled, field, stringID, options, isCreateRow, onChange, defaultOption}) => {
        const filteredSelectOptions = !options[id.regionID].length
            ? [(<option key={`key_${value}`} value={value}>{value}</option>)] : Object.values(options[id.regionID].reduce((a, c) => {
                if (Number(value)) {
                    a[value] = (<option key={`key_${value}`} value={value}>{value}</option>);
                }
                a[c.value] = (<option key={`key_${c.value}`} value={c.value}>{c.text}</option>);
                return a;
            }, {}));
        const filteredDefaultOption = <option key="default" value="">{defaultOption}</option>;

        return (
            <select
                value={value || ''}
                onChange={(e) => onChange({id, value: e.target.value, field, stringID, isCreateRow})}
                disabled={disabled}
            >
                {filteredDefaultOption}
                {filteredSelectOptions}
            </select>
        );
    }
};