import React from 'react';

import WOCrudTable from './admin-tools/WOCrudTable';
import options from './lib/table-props';

const WidgetOrder = () => (
    <WOCrudTable
        {...options.widget_order}
    />
);

export default WidgetOrder;
