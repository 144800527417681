export default {

    /* -- ON SUCCESS -- */
    successMsg: "Success! I'll call you as soon as possible.",
    successRoute: "/",

    /* -- TITLE -- */
    title:{
        text: "Fill out a message and I'll call you as soon as possible.",
        centered: false
    },

    /* -- INPUTS -- */
    inputs: [
        {
            name: "message",
            placeholder: "I would like to speak to you regarding...",
            type: "textarea",
            required: true
        }
    ],

    /* -- BUTTONS -- */
    buttons: [
        {
            text: "Yes, I want you to call me!",
            type: "submit"
        }
    ]
};
