import fetchOptions from "../../Admin/lib/fetch-options";

export default {

    /* -- ON SUCCESS -- */
    successRoute: "/login",
    successMsg: "Registration successful, please check your email. Going back to login page...",

    /* -- TITLE -- */
    title:{
        text: "Please Create an Account",
        centered: true
    },

    /* -- INPUTS -- */
    inputs: [
        {
            name: "firstName",
            placeholder: "First Name",
            type: "text",
            icon: require("../../img/icons/forms/person.svg"),
            required: true
        },
        {
            name: "lastName",
            placeholder: "Last Name",
            type: "text",
            icon: require("../../img/icons/forms/person.svg"),
            required: true
        },
        {
            name: "email",
            placeholder: "Email",
            type: "email",
            icon: require("../../img/icons/forms/email.svg"),
            required: true
        },
        {
            name: "phone",
            placeholder: "Phone Number",
            type: "text",
            icon: require("../../img/icons/forms/phone.svg"),
            required: true
        },
        {
            name: "repID",
            placeholder: "Sales Rep",
            type: "select",
            options: new Promise((resolve, reject) => {
                const hostname = process.env.NODE_ENV === 'development'
                            || !!process.env.REACT_APP_TEST_ENV
                    ? ''
                    : window.location.hostname;
                fetch(`/api/reps?hostname=${hostname}`, fetchOptions("GET"))
                    .then(res => res.json())
                    .then(reps => {
                        resolve(reps.map(rep => ({
                            value: rep.id,
                            text: rep.name
                        })));
                    })
                    .catch(error => reject(error));
            }),
            selected: (window) => {
                let vars = {};
                window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (_, key, value) => vars[key] = value);
                return vars.repID;
            },
            icon: require("../../img/icons/forms/contacts.svg"),
            required: true
        }
    ],

    /* -- BUTTONS -- */
    buttons: [
        {
            text: "Register",
            type: "submit",
    	    captcha: true
        }
    ],

    /* -- LINKS -- */
    links: [
        {
            text: "Already have an account? Click here.",
            url: "/login",
            share: ["email"]
        }
    ],

    recaptcha: true,
};
